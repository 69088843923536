import React from 'react'

function ServiceDetailsItems6() {
  return (
    <>
      <div className="service-items sec-mar-top">
        <div className="service-details-item">
          <div className="row">
            <div className="col-lg-6">
              <div className="single-details">
                <h4><span>01</span>Planificarea strategiei Social Media</h4>
                <p>În această etapă, se realizează planificarea și dezvoltarea unei strategii eficiente de administrare a social media. Se analizează obiectivele afacerii și se identifică publicul țintă. Se stabilesc platformele sociale relevante pentru afacere și se dezvoltă un plan de conținut, care include tipurile de postări, frecvența, tonul și mesajul potrivit. De asemenea, se stabilesc metricile cheie pentru a evalua succesul campaniilor de social media.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-details-thumb">
                <img decoding="async" src={process.env.PUBLIC_URL + '/img/blog/social-media-timeline-1.jpg'} alt="Trei persoane planifica strategia de promovare social media" loading="lazy" title="Promovare Social Media" />
              </div>
            </div>
          </div>
        </div>
        <div className="service-details-item">
          <div className="row">
            <div className="col-lg-6 or-2">
              <div className="single-details-thumb">
                <img decoding='async' src={process.env.PUBLIC_URL + '/img/blog/social-media-timeline-2.jpg'} alt="Unelte creare si gestionare continut social media, calculator, creion, ceas, ruleta, caseta" loading="lazy" title="Creare si gestionare continut social media" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-details">
                <h4><span>02</span>Crearea și gestionarea conținutului</h4>
                <p>În această etapă, se creează și se publică conținutul pe platformele de social media. Se dezvoltă postări, imagini, videoclipuri și alte materiale relevante pentru publicul țintă. Se utilizează strategii de gestionare a conținutului, cum ar fi calendarul editorial și programarea postărilor, pentru a menține o prezență activă și coerentă pe social media. De asemenea, se monitorizează și se interacționează cu comentariile și mesajele primite, asigurându-se o comunicare eficientă și implicare cu publicul.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="service-details-item">
          <div className="row">
            <div className="col-lg-6">
              <div className="single-details">
                <h4><span>03</span>Monitorizarea și analiza performanței</h4>
                <p>În această etapă, se monitorizează și se analizează performanța campaniilor de social media. Se urmăresc metricile cheie, cum ar fi numărul de vizualizări, angajamentul, distribuirea și traficul generat. Se utilizează instrumente de analiză pentru a evalua impactul și eficacitatea strategiilor și tacticii de social media. Pe baza acestor date, se iau decizii informate pentru a optimiza și îmbunătăți activitatea pe social media, asigurându-se că obiectivele afacerii sunt atinse.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-details-thumb">
                <img decoding='async' src={process.env.PUBLIC_URL + '/img/blog/social-media-timeline-3.jpg'} alt="Persoana monitorizeaza si analizeaza performata campaniilor social media" loading="lazy" title="Monitorizare si analiza performana social media" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ServiceDetailsItems6