import React from 'react'
import Breadcrumb from "../../../common/Breadcrumb";
import SubscribArea from "../../../common/SubscribArea";
import BlogDetailsWrap1 from "../Creareaidentitatiivizuale/BlogDetailsWrap1";
import SEO from '@americanexpress/react-seo';



function BlogDetails1() {
  return (
    <>
      <SEO
        title="Crearea Identității Vizuale"
        description="Articol de blog despre crearea identității vizuale în mediul online. Etapele identității vizuale"
        keywords={['branding si identitate vizuala', 'identitate vizuala']}
        siteUrl="https://e-brandy.ro"
        image={{
          src: 'https://e-brandy.ro/img/blog/blog-creare-identitate-vizuala.png'
        }}
      />
      <Breadcrumb pageName="Crearea identității vizuale" pageTitle="Crearea identității vizuale" />
      <BlogDetailsWrap1 />
      <SubscribArea />

    </>
  )
}

export default BlogDetails1
