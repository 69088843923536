import React from 'react'
import ProjectDetailsContent3 from './ProjectDetailsContent3'
import RelatedProject3 from './RelatedProject3'
import SidebarWidget3 from './SidebarWidget3'

function ProjectDetailsWrap3() {
  return (
    <div>
      <div className="project-details sec-mar-top">

        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <ProjectDetailsContent3 />
            </div>
            <div className="col-lg-4">
              <SidebarWidget3 />
            </div>
            <RelatedProject3 />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectDetailsWrap3