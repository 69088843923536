import React from "react";
import { Link } from "react-router-dom";
import ServiceDetailsItems3 from "./ServiceDetailsItems3";

function ServiceDetailsWrap3() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" })
  return (
    <>
      <div className="service-details sec-mar-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="service-details-content">
                <img decoding="async" src={process.env.PUBLIC_URL + '/img/blog/serviciu-marketing-digital-seo-thumnail.jpg'} alt="Tableta cu grafic ascendent campanie de marketing Romania" loading="lazy" title="Marketing digital si SEO" />
                <h3>
                  <i>
                    <img
                      src={process.env.PUBLIC_URL + '/img/icons/service-icon-6.png'}
                      alt="service icon images"
                    />
                  </i>
                  Marketing Digital–SEO
                </h3>
                <p>
                  Marketingul digital și SEO sunt cruciale pentru succesul afacerilor online. Ele asigură o prezență puternică în mediul digital, cresc vizibilitatea în motoarele de căutare și atrag trafic calificat. Prin intermediul strategiilor de marketing digital și SEO, afacerile pot ajunge la publicul lor țintă, construi relații cu clienții și genera mai multe conversii.
                </p>
                <p>
                  Importanța marketingului digital și SEO constă în faptul că acestea oferă o abordare măsurabilă și cu rezultate pe termen lung. Prin optimizarea site-ului pentru motoarele de căutare și promovarea în mediul digital, afacerile pot obține o poziție competitivă pe piață, creșterea notorietății și generarea de venituri. Ele permit afacerilor să se adapteze schimbărilor în comportamentul consumatorilor și să-și atingă obiectivele de afaceri.
                </p>
                <p>
                  Marketingul digital și SEO reprezintă investiții esențiale în succesul online al unei afaceri. Acestea permit creșterea brandului, atragerea de trafic organic și construirea unei comunități de clienți loiali. Prin intermediul strategiilor de marketing digital și SEO, afacerile pot obține un avantaj competitiv, crește vizibilitatea în mediul online și își pot atinge obiectivele de marketing și vânzări într-un mod eficient și rentabil.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar-widget">
                <h4>Suntem alături de tine în următoarele:</h4>
                <ul className="category">
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/serviciu-dezvoltare-web`} onClick={scrollTop}>
                      Dezvoltare web
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/serviciu-social-media`} onClick={scrollTop}>
                      Social Media
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/serviciu-mentenanta-it`} onClick={scrollTop}>
                      Mentenanță IT
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/serviciu-servicii-foto-video`} onClick={scrollTop}>
                      Servicii Foto-Video
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/serviciu-identitate-vizuala`} onClick={scrollTop}>
                      Logo și identitate vizuală
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/serviciu-marketing-digital-seo`} onClick={scrollTop}>
                      Marketing Digital-SEO
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="sidebar-banner">
                <img src={process.env.PUBLIC_URL + "/img/widget-banner-bg.jpg"} alt="Detalii contact pentru servicii digitalizare si branding" />
                <div className="banner-inner">
                  <h3>
                    <a href="tel:+40721126226">
                      Prețuri începând <span>de la</span><span> +4 0721 126 226</span><img className="angle" src={process.env.PUBLIC_URL + "/img/arrow-angle.png"} alt="Directie sageata catre detalii de contact pentru servicii de branding si digitalizare" />
                      <span> 50euro</span> </a>
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <ServiceDetailsItems3 />
        </div>
      </div>
    </>
  );
}

export default ServiceDetailsWrap3;
