import React from 'react'
import { firestoreDB } from '../../../firebase.config'
import { doc, getDoc, setDoc, collection } from 'firebase/firestore'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ContactWrapper() {

  const [error, setError] = React.useState('')
  const [success, setSuccess] = React.useState('')
  const [loading, setLoading] = React.useState(false)


  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    setError('')
    setSuccess('')
    try {
      const formData = {
        name: e.target.name.value,
        email: e.target.email.value,
        subject: e.target.subject.value,
        message: e.target.message.value,
        createdAt: new Date()
      }
      const emailData = {
        "subject": `${formData.name} - ${formData.subject}`,
        "text": formData.message,
        "to": "ebrandystudio@gmail.com",
        "from": formData.email,
        "html": `<body><h1>${formData.message}</h1></body>`,
        "attachements": []
      }
      await setDoc(doc(collection(firestoreDB, 'contactForm')), formData)
      await fetch('https://ebrandy-nextjs.vercel.app/api/sendEmail', {
        method: 'POST',
        headers: {
          'Accept': 'application/json"',
          'Authorization': 'Bearer {token}',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(emailData)

      })
      setSuccess('Mesajul a fost trimis cu succes.')
      toast.success('Mesajul a fost trimis cu succes.')
    } catch (e) {
      setError('A apărut o eroare. Vă rugăm să încercați din nou.')
      toast.error('A apărut o eroare. Vă rugăm să încercați din nou.')
    }
    setLoading(false)
  }


  return (
    <>
      <section className="contact-area sec-mar">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-5">
              <div className="contact-left">
                <div className="sec-title layout2">
                  <span>Suntem activi în mediul digital, putem discuta la:</span>
                  <p>Social Media e-Brandy</p>
                  <ul className="social-follow">
                    <li><a rel="noopener noreferrer" href="https://www.facebook.com/Ebrandystudios/"><i className="fab fa-facebook-f" /></a></li>
                    <li><a rel="noopener noreferrer" href="https://twitter.com/ebrandystudios/"><i className="fab fa-twitter" /></a></li>
                    <li><a rel="noopener noreferrer" href="https://www.pinterest.com/ebrandystudios/"><i className="fab fa-pinterest-p" /></a></li>
                    <li><a rel="noopener noreferrer" href="https://www.instagram.com/ebrandystudios/"><i className="fab fa-instagram" /></a></li>
                  </ul>
                </div>
                <div className="informations">
                  <div className="single-info">
                    <div className="icon">
                      <i className="fas fa-map-marker-alt" />
                    </div>
                    <div className="info">
                      <h3>Locație</h3>
                      <p>Bvd. Sudului, nr. 53A, clădirea A, ap. 31, Giroc, Timiș</p>
                    </div>
                  </div>
                  <div className="single-info">
                    <div className="icon">
                      <i className="fas fa-phone-alt" />
                    </div>
                    <div className="info">
                      <h3>Phone</h3>
                      <a href="tel:+40721126226">+4 0721 126 226</a>
                      <a href="tel:+40727652815">+4 0727 652 815</a>
                    </div>
                  </div>
                  <div className="single-info">
                    <div className="icon">
                      <i className="far fa-envelope" />
                    </div>
                    <div className="info">
                      <h3>Email</h3>
                      <a href="mailto:contact@ebrandy.ro">contact@ebrandy.ro</a>
                      <a href="mailto:tehnic@ebrandy.ro">tehnic@ebrandy.ro</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-7">
              <div className="mapouter">
                <div className="gmap_canvas">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7877.277086637938!2d21.241898783523972!3d45.730332280640766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47455dc2074051fd%3A0x26b4b3186bcded92!2sBulevardul%20Sudului%2053%2C%20Timi%C8%99oara!5e0!3m2!1sro!2sro!4v1686444518993!5m2!1sro!2sro" />
                </div>
              </div>
            </div>
          </div>
          <div className="getin-touch">
            <div className="row">
              <div className="col-md-6 col-lg-7">
                <div className="contact-form wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                  <h3>Lasă-ne un mesaj și te contactăm noi</h3>
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-12">
                        <input type="text" name="name" placeholder="Numele tău" />
                      </div>
                      <div className="col-md-6">
                        <input type="email" name="email" placeholder="Adresa de email" />
                      </div>
                      <div className="col-md-6">
                        <input type="text" name="subject" placeholder="Subiectul mesajului" />
                      </div>
                      <div className="col-12">
                        <textarea name="message" cols={30} rows={10} placeholder="Mesajul tău" defaultValue={""} />
                        <input type="Submit" defaultValue={
                          loading ? 'Se trimite...' : 'Trimite mesajul'
                        } />
                      </div>
                    </div>
                  </form>
                </div>
              </div>




              <div className="col-md-6 col-lg-5 wow animate fadeInUp" data-wow-delay="300ms" data-wow-duration="1500ms">
                <div className="call-banner">
                  <img decoding="async" src={process.env.PUBLIC_URL + "/img/call-center.png"} alt="Fata care lucreaza la call center, Web Design Timisoara" loading="lazy" title="Call center e-Brandy.ro" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer
        position="bottom-left"
      />
    </>
  )
}

export default ContactWrapper