import React, { useState } from 'react'
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";

function AboutArea(props) {
  const [isOpen, setOpen] = useState(false)
  return (
    <>
      <section className="about-area sec-mar-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 or-2 wow animate fadeIn" data-wow-delay="200ms" data-wow-duration="1500ms">
              <div className={`${"sec-title layout2"} ${props.white}`}>
                <span>Ce este e-Brandy</span>
                <h2>Despre Noi</h2>
              </div>
              <div className={`${"about-left"} ${props.layoutClass}`}>
                <h3>Construim împreună imaginea ta în mediul digital.</h3>
                <p>
                  Ne mândrim cu o atitudine orientată spre colaborare, parteneriat și rezultate. Înainte de implementarea fiecărei campanii sau construirea fiecărui website, dorim să iți înțelegem nevoiile și să observăm viziunea. </p>
                <p>Ne dorim ca brandul tău să fie mai mult decât vizibil. Împreună, țintim spre a fi o sursă de inspirație și a construi un brand unic, autentic și valoros.</p>

                <div className={`${"company-since"} ${props.layoutClass}`}>
                  <a href="https://learn.microsoft.com/ro-ro/users/cristianbogdansurcel-0041/credentials/714a8a83ddaaa35"> <img src={process.env.PUBLIC_URL + '/img/azure.png'} alt="Microsoft Azure, link" loading="lazy" /></a>
                  <a href="https://learn.microsoft.com/api/credentials/share/en-us/AlexandruFlorea-8951/39AAA931284F3BB5?sharingId=C78EADED7D6B43A1"><img decoding="async" src={process.env.PUBLIC_URL + '/img/microsoft-certified-associate-badge.svg'} alt="Microsoft Power Platform Associate, link" loading="lazy" /></a>
                  <a href="https://learn.microsoft.com/ro-ro/users/cristianbogdansurcel-0041/credentials/129faf439f165b6c"> <img decoding="async" src={process.env.PUBLIC_URL + '/img/power platforms.png'} alt="Power Platform Microsoft, link" loading="lazy" /></a>
                  <a href="https://learn.microsoft.com/ro-ro/users/cristianbogdansurcel-0041/credentials/1fed0928c3ade594"> <img decoding="async" src={process.env.PUBLIC_URL + '/img/security.png'} alt="Security Microsoft Azure, link" loading="lazy" /></a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 or-1 wow animate fadeIn" data-wow-delay="200ms" data-wow-duration="1500ms">
              <div className="about-right">
                <div className="banner-1">
                  <img decoding="async" src={process.env.PUBLIC_URL + '/img/e-brandy-despre-noi.jpg'} alt="Echipa e-Brandy - Agentie web design Romania" loading="lazy" title="Agentie Web Design Romania" />
                </div>
                <div className="banner-2">
                  <img decoding="async" src={process.env.PUBLIC_URL + '/img/e-brandy-despre-noi-1.jpg'} alt="Membru echipa web design e-Brandy prezinta servicii web oferite" loading="lazy" title="Web design Timisoara" />

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <React.Fragment>
        <ModalVideo
          channel="youtube"
          isOpen={isOpen}
          videoId="IUN664s7N-c"
          autoplay={1}
          onClose={() => setOpen(false)}
        />
      </React.Fragment>
    </>
  )
}

export default AboutArea