import React from "react";
import { Link } from "react-router-dom";
import ServiceDetailsItems4 from "./ServiceDetailsItems4";

function ServiceDetailsWrap4() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" })
  return (
    <>
      <div className="service-details sec-mar-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="service-details-content">
                <img decoding="async" src={process.env.PUBLIC_URL + '/img/blog/serviciu-mentenanta-it.jpg'} alt="Mentenanta IT pentru 12 calculatoare oprite intr-o incapere" loading="lazy" title="Serviciu mentenanta IT Romania" copyright="https://envato.com/" />
                <h3>
                  <i>
                    <img
                      src={process.env.PUBLIC_URL + '/img/icons/service-icon-3.png'}
                      alt="service icon images"
                    />
                  </i>
                  Mentenanță IT
                </h3>
                <p>
                  Mentenanța IT este crucială pentru orice afacere, indiferent de dimensiunea sau domeniul său de activitate. Prin efectuarea regulată a mentenanței, se asigură funcționarea optimă a infrastructurii IT, prevenind eventualele probleme și întreruperi neplanificate. Mentenanța IT contribuie la minimizarea timpului de nefuncționare, ceea ce permite afacerii să-și desfășoare operațiunile fără întreruperi și să ofere servicii neîntrerupte clienților săi.
                </p>
                <p>
                  O altă importanță a mentenanței IT este asigurarea securității și protecției datelor. Prin actualizarea și patch-urile de securitate, verificarea și curățarea sistemelor, mentenanța IT contribuie la prevenirea atacurilor cibernetice și la protejarea informațiilor sensibile ale afacerii și ale clienților săi. Mentenanța IT joacă un rol esențial în menținerea integrității și confidențialității datelor.
                </p>
                <p>
                  Mentenanța IT ajută, de asemenea, la optimizarea performanței sistemelor și a infrastructurii IT. Prin monitorizare, diagnosticare și optimizare periodică, se identifică și se rezolvă problemele de performanță, se optimizează configurațiile și se asigură funcționarea eficientă a echipamentelor și a rețelei. Acest lucru contribuie la creșterea productivității angajaților și la furnizarea unor servicii mai rapide și mai eficiente pentru clienți.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar-widget">
                <h4>Suntem alături de tine în următoarele:</h4>
                <ul className="category">
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/serviciu-dezvoltare-web`} onClick={scrollTop}>
                      Dezvoltare web
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/serviciu-social-media`} onClick={scrollTop}>
                      Social Media
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/serviciu-mentenanta-it`} onClick={scrollTop}>
                      Mentenanță IT
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/serviciu-servicii-foto-video`} onClick={scrollTop}>
                      Servicii Foto-Video
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/serviciu-identitate-vizuala`} onClick={scrollTop}>
                      Logo și identitate vizuală
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/serviciu-marketing-digital-seo`} onClick={scrollTop}>
                      Marketing Digital-SEO
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="sidebar-banner">
                <img src={process.env.PUBLIC_URL + "/img/widget-banner-bg.jpg"} alt="Detalii contact pentru servicii digitalizare si branding" />
                <div className="banner-inner">
                  <h3>
                    <a href="tel:+40721126226">
                      Prețuri începând <span>de la</span><span> +4 0721 126 226</span><img className="angle" src={process.env.PUBLIC_URL + "/img/arrow-angle.png"} alt="Directie sageata catre detalii de contact pentru servicii de branding si digitalizare" />
                      <span> 50euro</span> </a>
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <ServiceDetailsItems4 />
        </div>
      </div>
    </>
  );
}

export default ServiceDetailsWrap4;
