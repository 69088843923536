import React from 'react'

function SidebarWidget7() {
  return (
    <>
      <div className="sidebar-widget">
        <div className="client-box">
          <span>Client:</span>
          <h5>Nelu Cristian</h5>
        </div>
        <div className="client-box">
          <span>Companie:</span>
          <h5>Refrigeration Panels North Texas</h5>
        </div>
        <div className="client-box">
          <span>Location:</span>
          <h5>North Texas, USA</h5>
        </div>
        <div className="client-box">
          <span>Tipul proiectului:</span>
          <h5>Realizare website și promovare online</h5>
        </div>
        <div className="client-box">
          <span>Durata:</span>
          <h5>Servicii rapide în 30 zile</h5>
        </div>
      </div>
      <div className="sidebar-widget">
        <div className="contact-info">
          <h3>Ai nevoie de ajutor?</h3>
          <p>Suntem alături de tine, iată câteva modalități de contact:</p>
          <div className="cmpy-info">
            <div className="icon">
              <i className="fas fa-map-marker-alt" />
            </div>
            <div className="cnt">
              <h5>Locație</h5>
              <p>Bvd. Sudului, nr. 53A, clădirea A, ap. 31, Giroc, Timiș</p>
            </div>
          </div>
          <div className="cmpy-info">
            <div className="icon">
              <i className="fas fa-phone-alt" />
            </div>
            <div className="cnt">
              <h5>Contact</h5>
              <a href="tel:05661111985">+4 0721 126 226</a>
              <a href="tel:06571111576">+4 0727 652 815</a>
            </div>
          </div>
          <div className="cmpy-info">
            <div className="icon">
              <i className="far fa-envelope" />
            </div>
            <div className="cnt">
              <h5>Email</h5>
              <a href="mailto:contact@ebrandy.ro">contact@ebrandy.ro</a>
              <a href="mailto:tehnic@ebrandy.ro">tehnic@ebrandy.ro</a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SidebarWidget7