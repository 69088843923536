// Import the functions you need from the SDKs you need
import { initializeApp, getApps, getApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getRemoteConfig } from 'firebase/remote-config'
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
    apiKey: process.env.REACT_APP_BRANDY_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_BRANDY_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_BRANDY_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_BRANDY_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_BRANDY_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_BRANDY_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_BRANDY_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
export const firebaseApp = !getApps().length ? initializeApp(firebaseConfig) : getApp();
export const firestoreDB = getFirestore(firebaseApp);
export const firebaseAuth = getAuth(firebaseApp);
// export const analytics = getAnalytics(app);
// remoteConfig.settings = {
//     minimumFetchIntervalMillis: process.env.REACT_APP_LA_ENVIRONMENT === 'production' ? 3600000 : process.env.REACT_APP_LA_ENVIRONMENT === 'preview' ? 60000 : 10000,
//     fetchTimeoutMillis: 60000,
// };

// export const firebaseCloudMessaging = {
//     tokenInlocalforage: async () => {
//         const token = await localforage.getItem("fcm_token");
//         console.log("fcm_token tokenInlocalforage", token);
//         return token;
//     },
//     onMessage: async () => {
//         const messaging = getMessaging();
//         onMessage(messaging, (payload) => {
//             console.log("Message received. ", payload);
//             alert("Notificacion");
//         });
//     },

//     init: async function () {
//         try {
//             if ((await this.tokenInlocalforage()) !== null) {
//                 console.log("it already exists");
//                 return false;
//             }
//             console.log("it is creating it.");
//             const messaging = getMessaging(app);
//             await Notification.requestPermission();
//             getToken(messaging, {
//                 vapidKey:
//                     "MyvapidKeyFromFirebase Look for the documentation how to generate this token in Firebase. it is quite simple",
//             })
//                 .then((currentToken) => {
//                     console.log("current Token", currentToken);
//                     if (currentToken) {
//                         // Send the token to your server and update the UI if necessary
//                         // save the token in your database
//                         localforage.setItem("fcm_token", currentToken);
//                         console.log("fcm_token", currentToken);
//                     } else {
//                         // Show permission request UI
//                         console.log(
//                             "NOTIFICACION, No registration token available. Request permission to generate one."
//                         );
//                         // ...
//                     }
//                 })
//                 .catch((err) => {
//                     console.log(
//                         "NOTIFICACIONAn error occurred while retrieving token . "
//                     );
//                     console.log(err);
//                 });
//         } catch (error) {
//             console.error(error);
//         }
//     },
// };