const data = [
       {
        id: 1,
        title: "The Squat Babe",
        image: "img/project/thesquatbabe.png",
        categories: ["Dezvoltare", "Marketing"],
        details: "Dezvoltare website și mentenanță IT",
        url: "proiect-thesquatbabe",

    },
    {
        id: 8,
        title: "Modirom",
        image: "img/project/modirom.png",
        categories: ["Grafica", "Dezvoltare", "Social Media"],
        details: "Rebranding afacere și promovare online",
        url: "proiect-modirom",

    },
    {
        id: 5,
        title: "MTA Group",
        image: "img/project/mtagroup.jpg",
        categories: ["Dezvoltare", "Social Media"],
        details: "Website și Identitate Vizuală",
        url: "proiect-mtagroup",

    },
    {
        id: 6,
        title: "Ericco Solutions",
        image: "img/project/ericco.jpg",
        categories: ["Dezvoltare", "Grafica"],
        details: "Website și Identitate Vizuală",
        url: "proiect-ericcosolutions",
    },
    {
        id: 4,
        title: "Împreună pentru Anais",
        image: "img/project/impreunapentruanais.jpg",
        categories: ["Marketing", "Social Media", "Grafica", "Dezvoltare"],
        details: "Suntem alături de Anais",
        url: "proiect-impreunapentruanais",

    },
        {
        id: 7,
        title: "Refrigeration Panels North Texas",
        image: "img/project/refrisolutions.png",
        categories: ["Marketing", "Dezvoltare"],
        details: "Website și promovare online",
        url: "proiect-refri-solutions",

    },
    {
        id: 3,
        title: "FSG Studio",
        image: "img/project/fsgstudio.png",
        categories: ["Dezvoltare", "Social Media"],
        details: "Website și Social Media",
        url: "proiect-fsgstudio",

    },
    {
        id: 9,
        title: "KlinkerVest",
        image: "img/project/klinkervest.png",
        categories: ["Marketing", "Dezvoltare", "Social Media"],
        details: "Restructurare website și promovare online",
        url: "proiect-klinkervest",

    },


 
    {
        id: 11,
        title: "TSA Law",
        image: "img/project/tsalaw.png",
        categories: ["Dezvoltare", "Social Media"],
        details: "Restructurare website și social media",
        url: "proiect-tsalaw",

    },
    {
        id: 12,
        title: "CFR Timisoara",
        image: "img/project/cfrtimisoara.png",
        categories: ["Social Media"],
        details: "Social media și servicii foto-video",
        url: "proiect-cfrtimisoara",

    },
    {
        id: 13,
        title: "CSC Sacalaz",
        image: "img/project/cscsacalaz.png",
        categories: ["Social Media"],
        details: "Social media și servicii foto-video",
        url: "proiect-cscsacalaz",

    },
     {
    id: 2,
    title: "Cabana Râușor",
    image: "img/project/cabanarausor.jpg",
    categories: ["Grafica"],
    details: "Logo și Identitate vizuală",
    url: "proiect-cabanarausor",
    },
    {
        id: 10,
        title: "BC Volumetrisch",
        image: "img/project/bcvolumetrisch.jpg",
        categories: ["Marketing", "Dezvoltare"],
        details: "Website și analiză marketing",
        url: "proiect-bcvolumetrisch",

    }
    

    //
    //  {
    //    id: 2,
    //    title: "Cabana Râușor",
    //    image: "img/project/cabanarausor.jpg",
    //    categories: ["Grafica"],
    //    details: "Logo și Identitate vizuală",
    //    url: "proiect-cabanarausor",
    //   },
    //  
    //  {
    //      id: 1,
    //      title: "Ericco Solutions",
    //      image: "img/project/project-1.jpg",
    //      categories: ["Dezvoltare web", "Logo și identitate vizuală", "marketing"],
    //      details: "Realizare website și identitate vizuală",
    //      url: "proiect-1",
    //    },
    //    {
    //        id: 2,
    //        title: "MTA Group",
    //        image: "img/project/project-2.jpg",
    //        categories: ["Dezvoltare web", "Logo și identitate vizuală"],
    //        details: "Realizare website și identitate vizuală",
    //        url: "proiect-2",
    //
    //    },
    //    {
    //        id: 3,
    //        title: "Împreună pentru Anais",
    //        image: "img/project/project-3.jpg",
    //        categories: ["Dezvoltare website", "Logo și identitate vizuală", "Marketing Digital-SEO", "Social Media"],
    //        details: "Alături de micuța Anais pentru a învinge epilepsia",
    //        url: "proiect-3",
    //
    //    },
    //    {
    //        id: 4,
    //        title: "FSG Studio",
    //        image: "img/project/project-4.jpg",
    //        categories: ["grafica", "marketing",],
    //        details: "Servicii fotografiere personală",
    //        url: "proiect-4",
    //
    //    },
    //    {
    //        id: 5,
    //        title: "Litere volumetrice",
    //        image: "img/project/project-5.jpg",
    //        categories: "grafica",
    //        categories: "marketing",
    //        details: "Realizare website și analiză marketing",
    //        url: "proiect-5",
    //
    //    },
    //    {
    //        id: 6,
    //        title: "Cabana Râușor",
    //        image: "img/project/project-6.jpg",
    //        categories: "Grafica",
    //        categories: "Dezvoltare",
    //        details: "Identitate vizuală și realizare materiale grafice personalizate",
    //        url: "proiect-6",

];

export default data;