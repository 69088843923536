import React from 'react'
import ProjectDetailsContent2 from './ProjectDetailsContent2'
import RelatedProject2 from './RelatedProject2'
import SidebarWidget2 from './SidebarWidget2'

function ProjectDetailsWrap2() {
  return (
    <div>
      <div className="project-details sec-mar-top">

        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <ProjectDetailsContent2 />
            </div>
            <div className="col-lg-4">
              <SidebarWidget2 />
            </div>
            <RelatedProject2 />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectDetailsWrap2