import React from 'react'
import Breadcrumb from "../../../common/Breadcrumb";
import SubscribArea from "../../../common/SubscribArea";
import BlogDetailsWrap3 from "../Digitalizareineducatie/BlogDetailsWrap3";
import SEO from '@americanexpress/react-seo';


function BlogDetails3() {
  return (
    <>
      <SEO
        title="e-Brandy - Digitalizare și branding afacere | Agenție Promovare Online"
        description="Agentie Web Design Romania, Web Design Timisoara, Promovare online 100%"
        keywords={['agentie', 'web design']}
        siteUrl="https://e-brandy.ro"
        image={{
          src: 'https://e-brandy.ro/img/logo.png'
        }}
      />
      <Breadcrumb pageName="Digitalizarea în educație" pageTitle="Digitalizarea în educație" />
      <BlogDetailsWrap3 />
      <SubscribArea />

    </>
  )
}

export default BlogDetails3
