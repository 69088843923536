import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import SubscribArea from "../../common/SubscribArea";
import AboutWrap from "./AboutWrap";
import SEO from '@americanexpress/react-seo';


function About() {
  return (
    <>
      <SEO
        title="Despre echipa e-Brandy. Agenție web design Timișoara"
        description="Află mai multe despre echipa e-Brandy. Agenție web design Timișoara și promovare online."
        keywords={['agentie web design romania', 'detalii firma web design']}
        siteUrl="https://e-brandy.ro/despre-noi/"
        image={{
          src: 'https://e-brandy.ro/img/e-brandy-despre-noi.jpg'
        }}
      />
      <Breadcrumb pageName="Despre echipa e-Brandy" pageTitle="Despre echipa e-Brandy" />
      <AboutWrap />
      <SubscribArea />
    </>
  );
}

export default About;
