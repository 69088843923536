import React from 'react'
import BannerWidget from './BannerWidget'
import CatagoryWidget from './CatagoryWidget'
import Pagination from '../../common/Pagination'
import SingleBlog from './SingleBlog'

function BlogSidebarWrapper() {
    return (
        <>
            <div className="blog-grid sec-mar-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <CatagoryWidget />
                            <BannerWidget />
                        </div>
                        <div className="col-lg-8">
                            <div className="blog-item-grid">
                                <div className="row g-4">
                                    <SingleBlog image="/img/blog/blog-branding-romania.png" url="/blog-branding-romania/" date="31.05.2023" title="Branding în România" details="Branding în România?" />
                                    <SingleBlog image="/img/blog/blog-creare-identitate-vizuala.png" date="11.04.2023" url="/blog-crearea-identitatii-vizuale/" title="Crearea identității vizuale" details="Află cum poate afacerea ta să prindă contur" />
                                    <SingleBlog image="/img/blog/blog-digitalizare.png" date="31.03.2023" url="/blog-digitalizare/" title="Digitalizare" details="2023 - Anul digitalizării și revoluției digitale" />
                                    <SingleBlog image="/img/blog/blog-digitalizare-in-educatie.png" date="18.03.2022" url="/blog-digitalizare-in-educatie/" title="Digitalizare în educație" details="Educație nemărginită folosind internetul" />
                                    <SingleBlog image="/img/blog/blog-digitalizarea-in-institutii-publice.png" date="15.01.2022" url="/blog-digitalizare-in-institutii-publice/" title="Digitalizare în instituții publice" details="Trecerea în online, automatizarea proceselor fizice" />
                                    <SingleBlog image="/img/blog/blog-etapele-digitalizarii.png" date="20.12.2022" url="/blog-etapele-digitalizarii/" title="Treptat către digitalizare" details="Află care sunt etapele procesului de digitalizare" />
                                    <SingleBlog image="/img/blog/blog-treptat-in-online.png" date="01.12.2022" url="/blog-modalitati-de-afirmare-in-online/" title="Pășește în online, alături de noi" details="Cum poți ajunge eficient la publicul tău țintă" />
                                    <SingleBlog image="/img/blog/blog-respectarea-legislatiei.png" date="15.08.2022" url="/blog-respectarea-legislatiei/" title="Respectarea legislației pentru online" details="La ce trebuie să fii atent în online?" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlogSidebarWrapper