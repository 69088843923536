import React from 'react'
import { Link } from 'react-router-dom'

function HeroArea() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" })
  return (
    <>
      <section className="hero-area">
        <div className="ken-burns-slideshow">
          <img decoding="async" src={process.env.PUBLIC_URL + "/img/e-brandy-digitalizare-branding.jpg"} alt="Echipa eBrandy - Agentie web design Romania" loading="lazy" title="Agentie web design Romania" />
        </div>

        <div className="hero-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="hero-content">
                  <h1>Digitalizare și branding afacere | Agenție Promovare Online</h1>
                  <p>Digitalizare și branding. Conținut social media și promovare online. Dezvoltam brandul tău într-o poveste digitală completă. Experimentează creșterea în mediul online cu e-Brandy, agenția specializată în formarea și optimizarea brandului tău.
                  </p>
                  <div className="buttons">
                    <div className="cmn-btn">
                      <div className="line-1" />
                      <div className="line-2" />
                      <Link to={`${process.env.PUBLIC_URL}/despre-noi/`} onClick={scrollTop}>Despre Noi</Link>
                    </div>

                    <div className="cmn-btn layout-two">
                      <div className="line-1" />
                      <div className="line-2" />
                      <Link to={`${process.env.PUBLIC_URL}/proiecte/`} onClick={scrollTop}>Proiecte</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6" />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default HeroArea