import React from 'react'
import { Link } from 'react-router-dom'
import "swiper/css/autoplay";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper";
SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);

function ProjectDetailsContent4() {
  const projectSlider = {
    loop: true,
    slidesPerView: 1,
    navigation: {
      nextEl: ".swiper-button-next-c",
      prevEl: ".swiper-button-prev-c",
    }
  }
  return (
    <>
      <div className="project-details-content">
        <div className="project-thumb">
          <img decoding="async" src={process.env.PUBLIC_URL + '/img/project/fsgstudio.png'} alt="FSG Studio Logo" loading="lazy" title="FSG Studio" />
          <div className="tag">
            <a href="https://fsgstudio.ro">Website FSG Studio</a>
          </div>
        </div>
        <h3>FSG Studio</h3>
        <p>Parteneriatul nostru cu Florin Gherasim, talentatul fotograf și administrator al Funtastic Studio, a luat naștere din pasiunea comună pentru capturarea momentelor speciale prin intermediul imaginilor. Inițial, ne-am întâlnit pentru a explora modalitățile prin care să transmitem povestea unică a serviciilor sale fotografice printr-un website.</p>
        <p>În urma discuțiilor privind publicul țintă și obiectivele sale artistice, am început să conturăm detaliile proiectului, inclusiv structura și estetica site-ului. Fiecare aspect a fost adaptat pentru a reflecta personalitatea și stilul distinct al lui Florin, într-un mod care să atragă și să fidelizeze clienții săi.</p>

        <div className="clinet-need">
          <h4>Respectarea dorințelor clientului:</h4>
          <p>O atenție deosebită a fost acordată implementării unei experiențe online fluide și captivante pentru vizitatorii site-ului, astfel încât să se simtă inspirați și conectați cu munca lui Florin. </p>
          <p>Astăzi, ne bucurăm să asigurăm mentenanța website-ului <a href="https://fsgstudio.ro/">FSGSTUDIO</a>, iar recunoștința noastră merge către Florin pentru încrederea pe care ne-a acordat-o.</p>
        </div>
      </div>

    </>
  )
}

export default ProjectDetailsContent4