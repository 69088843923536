import React from 'react'
import ProjectDetailsContent10 from '../TheSquatBabe/ProjectDetailsContent10'
import RelatedProject10 from '../TheSquatBabe/RelatedProject10'
import SidebarWidget10 from '../TheSquatBabe/SidebarWidget10'

function ProjectDetailsWrap10() {
  return (
    <div>
      <div className="project-details sec-mar-top">

        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <ProjectDetailsContent10 />
            </div>
            <div className="col-lg-4">
              <SidebarWidget10 />
            </div>
            <RelatedProject10 />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectDetailsWrap10