import React from "react";
import AnimateLine from "../../common/AnimateLine";
import Breadcrumb from "../../common/Breadcrumb";
import SubscribArea from "../../common/SubscribArea";
import BlogSidebarWrapper from "./BlogSidebarWrapper";
import SEO from '@americanexpress/react-seo';


function BlogSidebar() {
  return (
    <>
      <SEO
        title="Blog e-Brandy. Agenție web design România"
        description="Citește ultimele noutăți despre digitalizare în românia. Blog e-Brandy."
        keywords={['articole agentie web design', 'agentie web design romania', 'articole solutii digitalizare']}
        siteUrl="https://e-brandy.ro/despre-noi/"
        image={{
          src: 'https://e-brandy.ro/img/blog/blog-creare-identitate-vizuala.png'
        }}
      />
      <div className="ebrandy-wrap">
        <AnimateLine />
        <Breadcrumb pageName="Blog e-Brandy" pageTitle="Blog e-Brandy" />
        <BlogSidebarWrapper />
        <SubscribArea />
      </div>
    </>
  );
}

export default BlogSidebar;
