import React from "react";
import AnimateLine from "../../../common/AnimateLine";
import Breadcrumb from "../../../common/Breadcrumb";
import SubscribArea from "../../../common/SubscribArea";
import ProjectDetailsWrap2 from "../CabanaRausor/ProjectDetailsWrap2";

function ProjectDetails2() {
  return (
    <>
      <div className="ebrandy-wrap">
        <AnimateLine />
        <Breadcrumb pageName="Cabana Râușor" pageTitle="Cabana Râușor" />
        <ProjectDetailsWrap2 />
        <SubscribArea />
      </div>
    </>
  );
}

export default ProjectDetails2;
