import React, { useReducer, useState } from "react";
import { Link } from "react-router-dom";
import data from "../../../data/data";
import "./ProjectCardFilter.css"; // Import CSS file for styling

const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
function ProjectCardFilter() {
  const [items, setItems] = useState(data);

  const filterItems = (category) => {
    if (category === "") {
      setItems(data);
    } else {
      const updatedItems = data.filter((item) =>
        item.categories.includes(category)
      );
      setItems(updatedItems);
    }
  };

  const initialState = { statementActive: "" };

  function reducer(state, action) {
    switch (action.type) {
      case "dataAll":
        filterItems("");
        return { statementActive: "dataAll" };
      case "Marketing":
        filterItems("Marketing");
        return { statementActive: "Marketing" };
      case "Dezvoltare":
        filterItems("Dezvoltare");
        return { statementActive: "Dezvoltare" };
      case "Grafica":
        filterItems("Grafica");
        return { statementActive: "Grafica" };
      case "Social Media":
        filterItems("Social Media");
        return { statementActive: "Social Media" };
      default:
        throw new Error();
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <ul className="isotope-menu">
            <li
              onClick={() => dispatch({ type: "dataAll" })}
              className={state.statementActive === "dataAll" ? "active" : ""}
            >
              Toate
            </li>
            <li
              onClick={() => dispatch({ type: "Marketing" })}
              data-filter=".marketing"
              className={state.statementActive.includes("Marketing") ? "active" : ""}
            >
              Marketing Digital-SEO
            </li>
            <li
              onClick={() => dispatch({ type: "Dezvoltare" })}
              data-filter=".Dezvoltare"
              className={state.statementActive.includes("Dezvoltare") ? "active" : ""}
            >
              Dezvoltare Web
            </li>
            <li
              onClick={() => dispatch({ type: "Grafica" })}
              data-filter=".Grafica"
              className={state.statementActive.includes("Grafica") ? "active" : ""}
            >
              Logo și identitate vizuală
            </li>
            <li
              onClick={() => dispatch({ type: "Social Media" })}
              data-filter=".Social Media"
              className={state.statementActive.includes("Social Media") ? "active" : ""}
            >
              Social Media
            </li>
          </ul>
        </div>
      </div>
      <div className="row g-4 project-items d-flex">
        {items.map((element) => {
          const { id, title, image, details, url, categories } = element;
          console.log(element);
          return (
            <div key={id} className="col-md-6 col-lg-4 single-item">
              <div className="item-img">
                <Link 
                onClick={scrollTop}
                to={`${process.env.PUBLIC_URL}/${url}`}>
                  <img decoding="async" src={image} alt="Logo parteneri e-Brandy. Clienti multumiti" loading="lazy" title="Parteneri e-Brany Web Design Romania" />
                </Link>
              </div>
              <div className="item-inner-cnt">
                <span>{title}</span>
                <h4>{details}</h4>
                <div className="categories">
                  {categories.map((category, index) => (
                    <span key={index}>{category}</span>
                  ))}
                </div>
                <div className="view-btn">
                <Link
                      onClick={scrollTop}
                      to={`${process.env.PUBLIC_URL}/${url}`}
                    >
                      Vezi detalii
                    </Link>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
/*
                    <Link
                      onClick={scrollTop}
                      to={`${process.env.PUBLIC_URL}/${url}`}
                    >
                      Vezi detalii
                    </Link>
*/

export default ProjectCardFilter;