import React from 'react'

function ServiceDetailsItems5() {
  return (
    <>
      <div className="service-items sec-mar-top">
        <div className="service-details-item">
          <div className="row">
            <div className="col-lg-6">
              <div className="single-details">
                <h4><span>01</span>Planificare și consultare</h4>
                <p>Înainte de toate, oferim ghidare și asistență specializată, ajutându-vă să vă planificați evenimentul în cel mai detaliat mod posibil. Experții noștri în foto-video vă vor asculta ideile, vă vor oferi sfaturi și vor crea împreună cu voi un concept personalizat, în vederea capturării perfecte a momentelor prețioase ale evenimentului dumneavoastră.</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-details-thumb">
                <img decoding="async" src={process.env.PUBLIC_URL + '/img/blog/planificare-consultare-foto-video.jpg'} alt="Doua persoane planifica un eveniment in detaliu" loading="lazy" title="Planificare si consultare foto-video" />
              </div>
            </div>
          </div>
        </div>
        <div className="service-details-item">
          <div className="row">
            <div className="col-lg-6 or-2">
              <div className="single-details-thumb">
                <img decoding="async" src={process.env.PUBLIC_URL + '/img/blog/sedinta-foto-video.jpg'} alt="Doi fotografi se pregatesc pentru sedinta foto-video Timisoara" loading="lazy" title="Sedinta foto-video Timisoara" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-details">
                <h4><span>02</span>Ședință fotografiere și/sau filmare</h4>
                <p>În această etapă, se desfășoară ședința de fotografiere și filmare propriu-zisă conform planului stabilit anterior. Fotograful sau echipa de filmare capturează imagini și secvențe video folosind echipamente profesionale. Se acordă atenție detaliilor, compoziției, iluminării și direcției artistice pentru a obține rezultate de înaltă calitate și a transmite mesajul dorit în mod eficient.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="service-details-item">
          <div className="row">
            <div className="col-lg-6">
              <div className="single-details">
                <h4><span>03</span>Editare și livrare finală </h4>
                <p>După încheierea ședinței de fotografiere și filmare, se trece la etapa de editare și post-procesare. Imaginile și secvențele video sunt selectate, editate, îmbunătățite și aduse într-o formă finală de înaltă calitate. Se adaugă efecte vizuale, se ajustează culorile și se realizează montajul final. Apoi, rezultatele finale sunt livrate clientului în formatul dorit, fie că este vorba de fișiere digitale sau de produse fizice precum albume foto sau DVD-uri.</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-details-thumb">
                <img decoding="async" src={process.env.PUBLIC_URL + '/img/blog/editare-livrare-finala.jpg'} alt="4 Fotografii ale unui oras editate in mod diferit" loading="lazy" title="Editare si livrare finala" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ServiceDetailsItems5