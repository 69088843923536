import React from 'react'
import { firestoreDB } from '../../firebase.config'
import { doc, getDoc, setDoc } from 'firebase/firestore'
import { ToastContainer, toast } from 'react-toastify';
import { config } from '../../config.js'
import 'react-toastify/dist/ReactToastify.css';



function SubscribArea() {
  const [email, setEmail] = React.useState('')
  const [error, setError] = React.useState('')
  const [success, setSuccess] = React.useState('')
  const [loading, setLoading] = React.useState(false)



  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    setError('')
    setSuccess('')
    try {
      const docRef = doc(firestoreDB, 'newsletterSubscribers', email)
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        setError('Emailul există deja în baza de date.')
        toast.error('Emailul există deja în baza de date.')
      } else {
        await setDoc(docRef, { email, createdAt: new Date() })
        setSuccess('Emailul a fost adăugat cu succes.')
        setEmail('')
        toast.success('Emailul a fost adăugat cu succes.')
      }
    } catch (e) {
      setError('A apărut o eroare. Vă rugăm să încercați din nou.')
      toast.error('A apărut o eroare. Vă rugăm să încercați din nou.')
    }
    setLoading(false)
  }



  return (
    <>
      <section className="subscribe-newsletter sec-mar-top">
        <div className="container">
          <div className="news-letter-content">
            <div className="row align-items-center">
              <div className="col-lg-6 wow animate fadeInLeft" data-wow-delay="200ms" data-wow-duration="1500ms">
                <div className="subscribe-cnt">
                  <span>Află primul noutățile e-Brandy</span>
                  <h3>Subscribe to Our</h3>
                  <strong>Newsletter</strong>
                </div>
              </div>
              <div className="col-lg-6 wow animate fadeInRight" data-wow-delay="200ms" data-wow-duration="1500ms">
                <div className="subscribe-form">
                  <form onSubmit={handleSubmit}>
                    <input type="email" placeholder="Introdu adresa de email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    <input type="submit" className="btn btn-primary" disabled={loading} value={'Abonează-te'} />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer
        position="bottom-left"
      />
    </>
  )
}



export default SubscribArea