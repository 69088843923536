import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper";
SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);

function RelatedProject3() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const projectSlider = {
    loop: true,
    slidesPerView: 3,
    spaceBetween: 30,
    navigation: {
      nextEl: ".swiper-button-next-c",
      prevEl: ".swiper-button-prev-c",
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      576: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      }
    }
  }
  return (
    <>
      <div className="project-slider">
        <h4>Alte proiecte realizate</h4>
        <div className="arrow">
          <div className="swiper-button-next-c">
            <i className="bi bi-arrow-right" />
          </div>
          <div className="swiper-button-prev-c">
            <i className="bi bi-arrow-left" />
          </div>
        </div>
        <Swiper {...projectSlider} className="swiper project-slide">
          <div className="swiper-wrapper">
            <SwiperSlide className="swiper-slide">
              <div className="single-item">
                <div className="item-img">
                  <Link
                    onClick={scrollTop}
                    to={`${process.env.PUBLIC_URL}/proiect-mtagroup/`}
                  >
                    <img decoding="async"
                      src={
                        process.env.PUBLIC_URL + "/img/project/mtagroup.jpg"
                      }
                      alt="MTA Group Logo, link" loading="lazy" title="MTA Group"
                    />
                  </Link>
                </div>
                <div className="item-inner-cnt">
                  <span>MTA Group</span>
                  <h4>Website Și Identitate Vizuală</h4>
                  <div className="view-btn">
                    <Link
                      onClick={scrollTop}
                      to={`${process.env.PUBLIC_URL}/proiect-mtagroup/`}
                    >
                      Vezi detalii
                    </Link>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
              <div className="single-item">
                <div className="item-img">
                  <Link
                    onClick={scrollTop}
                    to={`${process.env.PUBLIC_URL}/proiect-fsgstudio/`}
                  >
                    <img decoding="async"
                      src={
                        process.env.PUBLIC_URL + "/img/project/fsgstudio.png"
                      }
                      alt="FSG Studio Logo, link" loading="lazy" title="FSG Studio"
                    />
                  </Link>
                </div>
                <div className="item-inner-cnt">
                  <span>FSG Studio</span>
                  <h4>Website și Social Media</h4>
                  <div className="view-btn">
                    <Link
                      onClick={scrollTop}
                      to={`${process.env.PUBLIC_URL}/proiect-fsgstudio/`}
                    >
                      Vezi detalii
                    </Link>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
              <div className="single-item">
                <div className="item-img">
                  <Link
                    onClick={scrollTop}
                    to={`${process.env.PUBLIC_URL}/proiect-impreunapentruanais/`}
                  >
                    <img decoding="async"
                      src={
                        process.env.PUBLIC_URL + "/img/project/impreunapentruanais.jpg"
                      }
                      alt="Impreuna pentru Anais logo, link" loading="lazy" title="Impreuna pentru Anais"
                    />
                  </Link>
                </div>
                <div className="item-inner-cnt">
                  <span>Împreună pentru Anais</span>
                  <h4>Servicii complete</h4>
                  <div className="view-btn">
                    <Link
                      onClick={scrollTop}
                      to={`${process.env.PUBLIC_URL}/proiect-impreunapentruanais/`}
                    >
                      Vezi detalii
                    </Link>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide className="swiper-slide">
              <div className="single-item">
                <div className="item-img">
                  <Link
                    onClick={scrollTop}
                    to={`${process.env.PUBLIC_URL}/proiect-bcvolumetrisch/`}
                  >
                    <img decoding="async"
                      src={
                        process.env.PUBLIC_URL + "/img/project/bcvolumetrisch.jpg"
                      }
                      alt="BC Volumetrisch logo, link" loading="lazy" title="BC Volumetrisch"
                    />
                  </Link>
                </div>
                <div className="item-inner-cnt">
                  <span>BC Volumetrisch</span>
                  <h4>Website și analiza marketing</h4>
                  <div className="view-btn">
                    <Link
                      onClick={scrollTop}
                      to={`${process.env.PUBLIC_URL}/proiect-bcvolumetrisch/`}
                    >
                      Vezi detalii
                    </Link>
                  </div>
                </div>
              </div>
            </SwiperSlide>

          </div>

        </Swiper>
      </div>
    </>
  );
}

export default RelatedProject3;
