import React from 'react'
import ProjectDetailsContent8 from './ProjectDetailsContent8'
import RelatedProject8 from './RelatedProject8'
import SidebarWidget8 from './SidebarWidget8'

function ProjectDetailsWrap8() {
  return (
    <div>
      <div className="project-details sec-mar-top">

        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <ProjectDetailsContent8 />
            </div>
            <div className="col-lg-4">
              <SidebarWidget8 />
            </div>
            <RelatedProject8 />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectDetailsWrap8