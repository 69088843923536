import React from 'react'
import CountUp from "react-countup";
function FeaturesArea() {

  return (
    <>
      <section className="features-area">
        <div className="container">
          <div className="title-wrap  wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
            <div className="sec-title white">
              <span>2019 - 2024</span>
              <h2>De ce e-Brandy ?</h2>
              <p>Imposibil de oprit atunci când dorim să ne atingem obiectivele. Până acum am adunat următoarele</p>
            </div>
          </div>
          <div className="row g-4">
            <div className="col-md-6 col-lg-3 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="icon">
                    <img decoding="async" src={process.env.PUBLIC_URL + "/img/icons/feature-icon-1.png"} alt="Proiecte finalizate web design, imagine informativa" loading="lazy" />
                  </div>
                  <CountUp end={37} delay={3} duration={5} /> <sup>+</sup>
                  <h4>Proiecte complete</h4>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 wow animate fadeInUp" data-wow-delay="400ms" data-wow-duration="1500ms">
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="icon">
                    <img decoding="async" src={process.env.PUBLIC_URL + "/img/icons/feature-icon-2.png"} alt="Clienti satisfacuti web design, imagine informativa" loading="lazy" />
                  </div>
                  <CountUp end={23} delay={3} duration={5} /> <sup>+</sup>
                  <h4>Clienți satisfăcuți</h4>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 wow animate fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="icon">
                    <img decoding="async" src={process.env.PUBLIC_URL + "/img/icons/feature-icon-3.png"} alt="Membrii echipa web design Timisoara, imagine informativa" loading="lazy" />
                  </div>
                  <CountUp end={6} delay={2} duration={2} /> <sup>+</sup>
                  <h4>Responsabilii echipei</h4>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 wow animate fadeInUp" data-wow-delay="800ms" data-wow-duration="1500ms">
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="icon">
                    <img decoding="async" src={process.env.PUBLIC_URL + '/img/icons/feature-icon-1.png'} alt="Clienti fericiti web design, imagine informativa" loading="lazy" />
                  </div>
                  <CountUp end={12} delay={2} duration={3} /> <sup>+</sup>
                  <h4>Recunoașteri</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default FeaturesArea