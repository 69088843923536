import React from "react";
import ServiceCard from "./ServiceCard";

function ServiceCardWrap() {
  return (
    <>
      <div className="services-grid sec-mar">
        <div className="container">
          <div className="title-wrap">
            <div className="sec-title">
              <span>Soluțiile noastre</span>
              <h2>Servicii profesionale</h2>
            </div>
          </div>
          <div className="row g-4">
            <div class="col-md-6 col-lg-4 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
              <ServiceCard serviceNumber={1} image="/img/icons/service-icon-1.png" title="Dezvoltare website" details="Websiteul este o piesă importantă în reprezentarea afacerii tale în online. Respectăm viziunea și valorile tale și le transpunem în mediul digital." url="serviciu-dezvoltare-web" />
            </div>
            <div class="col-md-6 col-lg-4 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
              <ServiceCard serviceNumber={2} image="/img/icons/service-icon-2.png" title="Social Media" details="Atinge publicul țintă pe Facebook, Instagram, Twitter, LinkedIn, TikTok sau Pinterest! Monitorizăm performanța și ajustăm strategiile pentru rezultate clare și măsurabile." url="serviciu-social-media" />
            </div>
            <div class="col-md-6 col-lg-4 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
              <ServiceCard serviceNumber={3} image="/img/icons/service-icon-3.png" title="Mentenață IT" details="Menținem actualizarea, funcționarea continuă, securitatea și întreținerea pentru platforme online, echipamente și sisteme informatice. Fii conectat la ultimele informații tehnologice." url="serviciu-mentenanta-it" />
            </div>
            <div class="col-md-6 col-lg-4 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
              <ServiceCard serviceNumber={4} image="/img/icons/service-icon-4.png" title="Servicii Foto-Video" details="Descoperă cele mai bune rezoluții în producția video și imaginea pentru publicitate. Folosește calitate și tehnică profesională în imaginea produselor și conținutului tău." url="serviciu-servicii-foto-video" />
            </div>
            <div class="col-md-6 col-lg-4 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
              <ServiceCard serviceNumber={5} image="/img/icons/service-icon-5.png" title="Logo și identitate vizuală" details="Dezvoltăm împreună emblema ta grafică, reflectând valorile și personalitatea brandului tău, pentru o imagine coerentă și memorabilă în rândul publicului țintă." url="serviciu-identitate-vizuala" />
            </div>
            <div class="col-md-6 col-lg-4 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
              <ServiceCard serviceNumber={6} image="/img/icons/service-icon-6.png" title="Marketing Digital – SEO" details="Scriere strategică pentru pozițiile de top în motoarele de căutare. Monitorizarea și optimizarea traficului în obținerea de rezultate satisfăcătoare pe termen lung." url="serviciu-marketing-digital-seo" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceCardWrap;
