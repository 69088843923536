import React from 'react'

function ServiceDetailsItems3() {
  return (
    <>
      <div className="service-items sec-mar-top">
        <div className="service-details-item">
          <div className="row">
            <div className="col-lg-6">
              <div className="single-details">
                <h4><span>01</span>Analiză &amp; Optimizarea websiteului (SEO on-page) </h4>
                <p>În această etapă, se efectuează o analiză detaliată a site-ului web pentru a identifica posibilele probleme de optimizare pentru motoarele de căutare (SEO). Se analizează structura site-ului, conținutul, etichetele meta, URL-urile și alți factori relevanți. Se implementează apoi strategii de optimizare on-page, cum ar fi optimizarea cuvintelor cheie, îmbunătățirea experienței utilizatorului și optimizarea vitezei de încărcare a paginilor.</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-details-thumb">
                <img decoding="async" src={process.env.PUBLIC_URL + '/img/blog/analiza-si-optimizarea-website.jpg'} alt="Echipa analizeaza si optimizeaza seo on-page un website" loading="lazy" title="Analiza seo on-page website" />
              </div>
            </div>
          </div>
        </div>
        <div className="service-details-item">
          <div className="row">
            <div className="col-lg-6 or-2">
              <div className="single-details-thumb">
                <img decoding='async' src={process.env.PUBLIC_URL + '/img/blog/crearea-continutului-optimizat-seo.jpg'} alt="Grafic impartit in 12 parti cu unelte folositoare SEO, in centrul graficului scrie SEO" loading="lazy" title="Creare continut optimizat SEO" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-details">
                <h4><span>02</span>Crearea conținutului optimizat pentru SEO</h4>
                <p>În această etapă, se dezvoltă și se implementează strategii de creare a conținutului optimizat pentru motoarele de căutare. Se identifică cuvintele cheie relevante și se creează conținut valoros și relevant care să răspundă nevoilor și întrebărilor utilizatorilor. Se urmărește să se ofere conținut de calitate, bine structurat, cu elemente multimedia și link-uri relevante pentru a îmbunătăți vizibilitatea în motoarele de căutare.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="service-details-item">
          <div className="row">
            <div className="col-lg-6">
              <div className="single-details">
                <h4><span>03</span>Construirea autorității și promovarea websiteului (SEO off-page) </h4>
                <p>În această etapă, se lucrează la construirea autorității site-ului prin intermediul unor strategii de SEO off-page. Aceasta include obținerea de link-uri de calitate de la site-uri relevante și cu autoritate, precum și promovarea conținutului prin intermediul rețelelor sociale și altor canale de marketing online. De asemenea, se monitorizează performanța site-ului și se ajustează strategiile în funcție de rezultatele obținute.</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-details-thumb">
                <img decoding="async" src={process.env.PUBLIC_URL + '/img/blog/construirea-seo-offpage.jpg'} alt="Doua persoane inconjurati de simboluri SEO in afara unei cladiri" loading="lazy" title="Promovare website, seo off-page" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ServiceDetailsItems3