import React from 'react'
import ProjectDetailsContent7 from './ProjectDetailsContent7'
import RelatedProject7 from './RelatedProject7'
import SidebarWidget7 from './SidebarWidget7'

function ProjectDetailsWrap7() {
  return (
    <div>
      <div className="project-details sec-mar-top">

        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <ProjectDetailsContent7 />
            </div>
            <div className="col-lg-4">
              <SidebarWidget7 />
            </div>
            <RelatedProject7 />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectDetailsWrap7