import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function BlogDetailsContent7() {
  const [headings, setHeadings] = useState([]);
  const findHeadings = () => {
    const contentHeadings = document.querySelectorAll('.blog-details-content h4');
    const headingsArray = Array.from(contentHeadings).map((heading) => ({
      id: heading.id,
      text: heading.textContent,
    }));
    setHeadings(headingsArray);
  };

  useEffect(() => {
    findHeadings();
  }, []);

  const shareOnFacebook = () => {
    window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(window.location.href), '_blank');
  };

  const shareOnTwitter = () => {
    window.open('https://twitter.com/intent/tweet?url=' + encodeURIComponent(window.location.href), '_blank');
  };

  const shareOnPinterest = () => {
    window.open('https://www.pinterest.com/pin/create/button/?url=' + encodeURIComponent(window.location.href), '_blank');
  };

  const shareOnInstagram = () => {
    if (navigator.share) {
      navigator
        .share({
          url: window.location.href,
        })
        .then(() => console.log('Shared successfully'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
      console.log('Instagram sharing not supported');
    }
  };

  const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

  return (
    <>
      <div className="blog-details-content">
        <center><h2>Navigarea în reglementările online din România: Respectarea granițelor digitale</h2></center>
        <p>În era digitală, internetul a devenit o parte integrantă a vieții noastre, conectând oameni din toate colțurile lumii. Cu toate acestea, odată cu acest progres tehnologic, apare și nevoia de reglementare pentru a asigura un mediu online sigur și securizat. În România, au fost instituite reglementări specifice pentru a reglementa activitățile online și este esențial ca atât rezidenții, cât și vizitatorii să respecte aceste reglementări. În această postare pe blog, vom explora importanța respectării reglementărilor online din România și vom discuta despre domeniile cheie în care conformitatea este crucială.</p>
        <div className="details-thumb">
          <img decoding="async" src={process.env.PUBLIC_URL + "/img/blog/blog-respectarea-legislatiei.png"} alt="Balanta pentru respectarea legislatiei web din Romania" loading="lazy" title="Legislatie web Romania" />
        </div>
        <nav>
          <h3>Cuprins</h3>
          {headings.map((heading, index) => (
            <li key={heading.id}>
              <a href={`#${heading.id}`}>{`${(index + 1).toString().padStart(2, '0')}. ${heading.text}`}</a>
            </li>
          ))}
        </nav>
        <div className='divider'></div>
        <div className="blog-quote">
          <i className="fas fa-quote-left" />
          <p>Respectarea legii este piatra de temelie a democrației.<br></br> - Barack Obama, Fost președinte al S.U.A</p>
        </div>
        <h4 id="Protecția datelor și a vieții private">Protecția datelor și a vieții private</h4>
        <div className="special">
          <p>Protecția datelor și confidențialitatea sunt aspecte critice ale reglementărilor online din România. Regulamentul general privind protecția datelor (GDPR) a fost adoptat de România, asigurând că persoanele fizice au control asupra datelor lor personale. Acest lucru înseamnă că întreprinderile și organizațiile care își desfășoară activitatea în România trebuie să trateze informațiile personale în mod responsabil, să obțină consimțământul atunci când este necesar și să protejeze datele împotriva accesului neautorizat sau a încălcărilor.</p>
        </div>
        <h4 id="Obținerea consimțământului explicit">Obținerea consimțământului explicit</h4>
        <div className="special">
          <p>Respectarea acestor reglementări presupune obținerea consimțământului explicit înainte de a colecta și prelucra datele cu caracter personal, implementarea unor măsuri de securitate adecvate și oferirea persoanelor fizice posibilitatea de a accesa, modifica sau șterge datele lor. Respectarea acestor reglementări nu numai că protejează drepturile persoanelor fizice, ci și încurajează încrederea între întreprinderi și clienții lor.</p>
        </div>
        <div class="space"></div>
        <center><img decoding="async" src={process.env.PUBLIC_URL + "/img/blog/respectarea-legislatiei-din-romania-1.png"} alt="Judecator imbracat la costum respecta legislatia web" loading="lazy" title="Respectarea legislatiei web" /></center>
        <div class="space"></div>
        <h4 id="Conținutul online">Conținutul online</h4>
        <div className="special">
          <p>În România, există reglementări pentru a se asigura că conținutul online rămâne respectuos, legal și lipsit de elemente dăunătoare. Platformele online, site-urile web și canalele de social media trebuie să respecte aceste reglementări pentru a menține un mediu digital sigur și incluziv. Este esențial să se evite răspândirea discursului instigator la ură, a conținutului defăimător sau implicarea în orice formă de hărțuire cibernetică.</p>
        </div>
        <h4 id="Atenția la conținutul partajat">Atenția la conținutul partajat</h4>
        <div className="special">
          <p>Respectarea acestor reglementări înseamnă să fiți atenți la conținutul pe care îl partajați, să respectați legile privind drepturile de autor și să vă abțineți de la diseminarea de informații false sau înșelătoare. Fiind cetățeni digitali responsabili, contribuim la o comunitate online mai sănătoasă, care promovează dialogul deschis și respectul reciproc.</p>
        </div>
        <div class="space"></div>
        <center><img decoding="async" src={process.env.PUBLIC_URL + "/img/blog/respectarea-legislatiei-din-romania-2.png"} alt="Cladire judecatoreasca in stil baroc, langa un drum, fundal colorat" loading="lazy" title="Respectarea drepturilor web" copyright="" /></center>
        <div class="space"></div>
        <h4 id="Comerțul electronic și drepturile consumatorilor">Comerțul electronic și drepturile consumatorilor</h4>
        <div className="special">
          <p>România are reglementări specifice care reglementează activitățile de comerț electronic și protecția drepturilor consumatorilor. Dacă conduceți o afacere online sau vă implicați în activități de comerț electronic în România, este esențial să vă familiarizați cu aceste reglementări. Acestea acoperă domenii precum prețuri clare, termeni și condiții transparente, procesarea sigură a plăților și politici corecte de returnare.</p>
        </div>
        <h4 id="Satisfacția conumatorilor">Satisfacția conumatorilor</h4>
        <div className="special">
          <p>Prin respectarea acestor reglementări, întreprinderile online își demonstrează angajamentul față de satisfacția consumatorilor și creează încredere în rândul clienților lor. De asemenea, se asigură că consumatorii au căi de atac în caz de litigii sau practici neloiale.</p>
        </div>
        <div class="space"></div>
        <center><img decoding="async" src={process.env.PUBLIC_URL + "/img/blog/respectarea-legislatiei-din-romania-4.png"} alt="Judecator increzator de respectarea legislatiei web" loading="lazy" title="Agentie web design Romania" /></center>
        <div class="space"></div>
        <h4 id="Securitatea cibernetică și siguranța online">Securitatea cibernetică și siguranța online</h4>
        <div className="special">
          <p>Pe măsură ce tehnologia avansează, cresc și riscurile asociate cu criminalitatea informatică și amenințările online. România a stabilit reglementări pentru a spori securitatea cibernetică și pentru a proteja persoanele și organizațiile de atacurile cibernetice. Respectarea acestor reglementări înseamnă implementarea unor măsuri de securitate puternice, actualizarea regulată a software-ului și a sistemelor și vigilența față de tentativele de phishing sau malware.</p>
        </div>
        <h4 id="Respectarea reglementărilor">Respectarea reglementărilor</h4>
        <div className="special">
          <p>Respectarea reglementărilor privind securitatea online nu numai că vă protejează propria prezență digitală, dar contribuie și la securitatea generală a ecosistemului online. Urmând cele mai bune practici și rămânând informați cu privire la amenințările emergente, putem crea un mediu online mai sigur pentru toată lumea.</p>
        </div>
        <div className="tag-share">
          <div className="line-tag">
            <span>Tag:</span>
            <Link to={`${process.env.PUBLIC_URL}/serviciu-dezvoltare-web`} onClick={scrollTop}>web,</Link>
            <Link to={`${process.env.PUBLIC_URL}/serviciu-identitate-vizuala`} onClick={scrollTop}>branding,</Link>
            <Link to={`${process.env.PUBLIC_URL}/serviciu-social-media`} onClick={scrollTop}>social media,</Link>
          </div>
          <div className="share-blog">
            <span>Share On:</span>
            <ul className="social-share-blog">
              <li>
                <a href="#" onClick={shareOnFacebook}>
                  <i className="fab fa-facebook-f" />
                </a>
              </li>
              <li>
                <a href="#" onClick={shareOnTwitter}>
                  <i className="fab fa-twitter" />
                </a>
              </li>
              <li>
                <a href="#" onClick={shareOnPinterest}>
                  <i className="fab fa-pinterest-p" />
                </a>
              </li>
              <li>
                <a href="#" onClick={shareOnInstagram}>
                  <i className="fab fa-instagram" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div >
    </>
  );
}

export default BlogDetailsContent7;