import React from 'react'
import { Link } from 'react-router-dom'
import "swiper/css/autoplay";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper";
SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);

function ProjectDetailsContent2() {
  const projectSlider = {
    loop: true,
    slidesPerView: 1,
    navigation: {
      nextEl: ".swiper-button-next-c",
      prevEl: ".swiper-button-prev-c",
    }
  }
  return (
    <>
      <div className="project-details-content">
        <div className="project-thumb">
          <img decoding="async" src={process.env.PUBLIC_URL + '/img/project/cabanarausor.jpg'} alt="Cabana Rausor logo" loading="lazy" title="Cabana Rausor" />
          <div className="tag">
            <a href="https://cabanarausor.ro">Website Cabana Râușor</a>
          </div>
        </div>
        <h3>Cabana Râușor</h3>
        <p>Colaboarea cu Cosmin a început prin stabilirea publicului care se dorește a fi atins. Ultrior, am dezvoltat o litsă de activități ce pot fi desfășurate în Râușor, incluzând fiecare traseu montan. </p>
        <div className="clinet-need">
          <h4>Înțelegerea nevoilor clientului</h4>
          <p>Cosmin a stat aproape de noi în crearea conținutului pentru site, oferind informații și detalii despre Râușor și Hunedoara. Websiteul a fost optimizat SEO, fotografiile au fost făcute și alese în strânsă colaborare cu Cosmin, iar rezultatele au fost vizibile încă din primele săptămâni de implementare. Mulțumim Cosmin pentru colaboare și sperăm că vom putea lucra împreună și în viitor! </p>
        </div>
      </div>

    </>
  )
}

export default ProjectDetailsContent2