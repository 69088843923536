import React from 'react'

function ServiceDetailsItems4() {
  return (
    <>
      <div className="service-items sec-mar-top">
        <div className="service-details-item">
          <div className="row">
            <div className="col-lg-6">
              <div className="single-details">
                <h4><span>01</span>Monitorizare și diagnosticare</h4>
                <p>În această etapă, se realizează monitorizarea continuă a infrastructurii IT pentru a identifica eventualele probleme sau anomalii. Se utilizează instrumente de monitorizare și diagnosticare pentru a verifica starea sistemelor, a rețelei și a altor componente IT. Scopul este de a identifica și rezolva problemele înainte ca acestea să afecteze funcționarea normală a sistemelor.</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-details-thumb">
                <img decoding="async" src={process.env.PUBLIC_URL + '/img/blog/intretinere-preventiva-actualizari.jpg'} alt="O persoana care monitorizeaza functionarea optima website pe un ecran" loading="lazy" title="Monitorizare si diagnosticare website" />
              </div>
            </div>
          </div>
        </div>
        <div className="service-details-item">
          <div className="row">
            <div className="col-lg-6 or-2">
              <div className="single-details-thumb">
                <img decoding="async" src={process.env.PUBLIC_URL + '/img/blog/monitorizare-diagnosticare-it.jpg'} alt="Intretinere calculatoare si actualizare pentru functionare optima" loading="lazy" title="Intretinere preventiva si actualizari" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-details">
                <h4><span>02</span>Întreținere preventivă și actualizări</h4>
                <p>În această etapă, se implementează acțiuni preventive pentru a menține infrastructura IT în stare optimă. Se efectuează actualizări de securitate, se verifică și se curăță echipamentele fizice, se optimizează configurările sistemelor și se efectuează teste de performanță. Scopul este de a preveni problemele și de a asigura funcționarea optimă a infrastructurii IT.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="service-details-item">
          <div className="row">
            <div className="col-lg-6">
              <div className="single-details">
                <h4><span>03</span>Rezolvare incidente tehnice </h4>
                <p>În această etapă, se rezolvă incidentele sau problemele semnalate de utilizatori sau detectate în timpul monitorizării. Se oferă suport tehnic pentru a remedia problemele și a restabili funcționarea normală a sistemelor. Acest lucru implică diagnosticarea și remedierea erorilor, actualizarea software-ului, restaurarea datelor sau orice alte acțiuni necesare pentru a rezolva problemele raportate.</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-details-thumb">
                <img decoding="async" src={process.env.PUBLIC_URL + '/img/blog/rezolvarea-incidentelor-suport-tehnic.jpg'} alt="Persoana sta la birou in fata calculatorului pentru rezolvarea incidente tehnice website" loading="lazy" title="Rezolvare incidente tehnice" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ServiceDetailsItems4