import React from "react";
import { Link } from "react-router-dom";
import ServiceDetailsItems1 from "./ServiceDetailsItems1";

function ServiceDetailsWrap1() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" })
  return (
    <>
      <div className="service-details sec-mar-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="service-details-content">
                <img decoding="async" src={process.env.PUBLIC_URL + '/img/blog/blog-thumnail.jpg'} alt="Echipament agentie web design, 3 monitoare calculator si aplicatia Spotify deschisa pe ecranul unui iphone" loading="lazy" title="Dezvoltare website Timisoara" />
                <h3>
                  <i>
                    <img
                      src={process.env.PUBLIC_URL + '/img/icons/service-icon-1.png'}
                      alt="Icon serviciu dezvoltare website"
                    />
                  </i>
                  Dezvoltare website
                </h3>
                <p>
                  e-Brandy este mereu orientat spre crearea soluțiilor tehnologice personalizate. Echipa noastră specializată în programare și design pune la dispoziție servicii de dezvoltare web inovatoare, adaptate nevoilor și cerințelor clienților. Oferim soluții scalabile și securizate, optimizate pentru a asigura o experiență utilizator excepțională.
                </p>
                <p>
                  Fie că aveți nevoie de o aplicație web complexă sau de o platformă simplă, suntem pregătiți să vă ajutăm să transformați ideile în realitate. Vă garantăm soluții personalizate, livrate la timp și la cel mai înalt standard de calitate.
                </p>
                <p>
                  Indiferent dacă aveți nevoie de o aplicație web de comerț electronic, o platformă de gestionare a conținutului sau o soluție personalizată, suntem aici să vă ajutăm. Punem accent pe colaborare strânsă cu clienții noștri pentru a înțelege în profunzime cerințele lor și pentru a livra produse finale care să depășească așteptările. Contactați-ne astăzi pentru a discuta despre proiectul dvs. de dezvoltare a aplicațiilor web.{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar-widget">
                <h4>Suntem alături de tine în următoarele:</h4>
                <ul className="category">
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/serviciu-dezvoltare-web`} onClick={scrollTop}>
                      Dezvoltare web
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/serviciu-social-media`} onClick={scrollTop}>
                      Social Media
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/serviciu-mentenanta-it`} onClick={scrollTop}>
                      Mentenanță IT
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/serviciu-servicii-foto-video`} onClick={scrollTop}>
                      Servicii Foto-Video
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/serviciu-identitate-vizuala`} onClick={scrollTop}>
                      Logo și identitate vizuală
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/serviciu-marketing-digital-seo`} onClick={scrollTop}>
                      Marketing Digital-SEO
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="sidebar-banner">
                <img src={process.env.PUBLIC_URL + "/img/widget-banner-bg.jpg"} alt="Detalii contact pentru servicii digitalizare si branding" />
                <div className="banner-inner">
                  <h3>
                    <a href="tel:+40721126226">
                      Prețuri începând <span>de la</span><span> +4 0721 126 226</span><img className="angle" src={process.env.PUBLIC_URL + "/img/arrow-angle.png"} alt="Directie sageata catre detalii de contact pentru servicii de branding si digitalizare" />
                      <span> 50euro</span> </a>
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <ServiceDetailsItems1 />
        </div>
      </div>
    </>
  );
}

export default ServiceDetailsWrap1;
