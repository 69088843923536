import React from "react";
import { Link } from "react-router-dom";
import "swiper/css/autoplay";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Autoplay,
  Navigation,
} from "swiper";
SwiperCore.use([Navigation, Autoplay]);

function ServiceArea(props) {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const serviceSlider = {
    loop: true,
    speed: 1000,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    slidesPerView: 3,
    spaceBetween: 30,

    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      }
    }
  }
  return (
    <>
      <section className={`${"services-area sec-mar"} ${props.layoutClass}`}>
        <div className="container">
          <div
            className="title-wrap wow animate fadeInUp"
            data-wow-delay="200ms"
            data-wow-duration="1500ms"
          >
            <div className={`${"sec-title"} ${props.white}`}>
              <span>Soluțiile noastre pentru afacerea ta</span>
              <h2>Servicii</h2>
              <p>
                Oferim abordări strategice și creative, ce duc la rezultate clare, măsurabile, vizibile.  <span></span> Hai alături de noi pentru a scrie împreună povestea brandului tău!
              </p>
            </div>
          </div>
          <Swiper {...serviceSlider} className="swiper services-slider">
            <div className="swiper-wrapper">
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>01</span>
                  <div className="icon">
                    <img decoding="async"
                      src={
                        process.env.PUBLIC_URL + "/img/icons/service-icon-1.png"
                      }
                      alt="Dezvoltare website, icon" loading="lazy" title="Dezvoltare website Romania"
                    />
                  </div>
                  <h4>Dezvoltare website</h4>
                  <p>
                    Websiteul este o piesă importantă în reprezentarea afacerii tale în online. Respectăm viziunea și valorile tale și le transpunem în mediul digital.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/serviciu-dezvoltare-web`}
                      onClick={scrollTop}
                    >
                      Află mai multe
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>02</span>
                  <div className="icon">
                    <img decoding="async"
                      src={
                        process.env.PUBLIC_URL + "/img/icons/service-icon-2.png"
                      }
                      alt="Social media, icon" title="Social Media Romania"
                    />
                  </div>
                  <h4>Social Media</h4>
                  <p>
                    Atinge publicul țintă pe Facebook, Instagram, Twitter, LinkedIn, TikTok sau Pinterest! Monitorizăm performanța și ajustăm strategiile pentru rezultate clare și măsurabile.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/serviciu-social-media`}
                      onClick={scrollTop}
                    >
                      Află mai mult
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>03</span>
                  <div className="icon">
                    <img decoding="async"
                      src={
                        process.env.PUBLIC_URL + "/img/icons/service-icon-6.png"
                      }
                      alt="Marketing SEO, icon" title="Marketing web Romania"
                    />
                  </div>
                  <h4>Marketing Digital - SEO</h4>
                  <p>
                    Scriere strategică pentru pozițiile de top în motoarele de căutare. Monitorizarea și optimizarea traficului în obținerea de rezultate satisfăcătoare pe termen lung.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/serviciu-marketing-digital-seo`}
                      onClick={scrollTop}
                    >
                      Află mai multe
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>04</span>
                  <div className="icon">
                    <img decoding="async"
                      src={
                        process.env.PUBLIC_URL + "/img/icons/service-icon-4.png"
                      }
                      alt="Serviciu foto-video, icon"
                    />
                  </div>
                  <h4>Servicii Foto-Video</h4>
                  <p>
                    Descoperă cele mai bune rezoluții în producția video și imaginea pentru publicitate. Folosește calitate și tehnică profesională în imaginea produselor și conținutului tău.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/serviciu-servicii-foto-video`}
                      onClick={scrollTop}
                    >
                      Află mai multe
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>05</span>
                  <div className="icon">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/icons/service-icon-5.png"
                      }
                      alt="Identitate vizuala Romania, icon"
                    />
                  </div>
                  <h4>Logo și identitate vizuală</h4>
                  <p>
                    Dezvoltăm împreună emblema ta grafică, reflectând valorile și personalitatea brandului tău, pentru o imagine coerentă și memorabilă în rândul publicului țintă.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/serviciu-identitate-vizuala`}
                      onClick={scrollTop}
                    >
                      Află mai multe
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>06</span>
                  <div className="icon">
                    <img decoding="async"
                      src={
                        process.env.PUBLIC_URL + "/img/icons/service-icon-3.png"
                      }
                      alt="Mentenanta website, icon"
                    />
                  </div>
                  <h4>Mentenață soluții digitale</h4>
                  <p>
                    Menținem actualizarea, funcționarea continuă, securitatea și întreținerea pentru platforme online, echipamente și sisteme informatice. Fii conectat cu ultimele informații tehnologice.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/serviciu-mentenanta-it`}
                      onClick={scrollTop}
                    >
                      Află mai multe
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
            </div>
          </Swiper>
          <div className="swiper-button-next" />
          <div className="swiper-button-prev" />
        </div>
      </section>
    </>
  );
}

export default ServiceArea;
