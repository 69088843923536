import React from 'react'

function ServiceDetailsItems1() {
  return (
    <>
      <div className="service-items sec-mar-top">
        <div className="service-details-item">
          <div className="row">
            <div className="col-lg-6">
              <div className="single-details">
                <h4><span>01</span>Analiza nevoilor clientului </h4>
                <p>Înaintea realizării unei aplicații web, analiza nevoilor clientului este crucială. Echipa de dezvoltare interacționează cu clientul, înțelegând cerințele și obiectivele acestuia. Se colectează informații despre domeniul de activitate, publicul țintă și funcționalitățile dorite. Urmează etapa de analiză și evaluare, identificând provocările și oferind soluții tehnice inovatoare. Se iau în considerare securitatea datelor, scalabilitatea sistemului și integrările cu alte aplicații. Rezultatul este un document de specificații și cerințe, ghid pentru dezvoltare. Analiza nevoilor clientului asigură o aplicație web eficientă și adaptată.</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-details-thumb">
                <img decoding="async" src={process.env.PUBLIC_URL + '/img/blog/agentie-web-design-romania.jpg'} alt="Masa web designer, cana de cafeam tableta grafica, o floare in ghiveci si un pix" loading="lazy" title="Birou web design Romania" />
              </div>
            </div>
          </div>
        </div>
        <div className="service-details-item">
          <div className="row">
            <div className="col-lg-6 or-2">
              <div className="single-details-thumb">
                <img decoding="async" src={process.env.PUBLIC_URL + '/img/blog/creare-design-website.jpg'} alt="Un designer website tine in mana un iphone deasupra unui MacBook" loading="lazy" title="Dezvoltare website" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-details">
                <h4><span>02</span>Crearea designului aplicației</h4>
                <p>Intocmirea designului unei aplicatii web reprezinta un proces esential pentru a crea o experienta placuta si intuitiva pentru utilizatori. Echipa de design examineaza cerintele si obiectivele, efectueaza cercetari si analizeaza publicul tinta. Se creeaza wireframe-uri, se stabilesc elementele vizuale, cum ar fi culorile si tipografia, si se dezvolta interfata grafica. Design-ul se concentreaza pe usabilitate, accesibilitate si estetica, astfel incat aplicatia web sa fie functionala si atragatoare. Testarea si iterarea continua asigura o imbunatatire constanta a experientei utilizatorului.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="service-details-item">
          <div className="row">
            <div className="col-lg-6">
              <div className="single-details">
                <h4><span>03</span>Dezvoltarea aplicației web</h4>
                <p>Dezvoltarea finală a unei aplicații web este etapa în care se aduce la viață întregul proiect. Echipa de dezvoltare implementează funcționalitățile, integrează designul și optimizează performanța. Se testează în profunzime pentru a identifica și remedia eventuale erori sau probleme de compatibilitate. Se asigură securitatea datelor și se optimizează pentru diverse dispozitive și browsere. În final, aplicația web este pregătită pentru lansare, oferind o experiență fluidă și eficientă utilizatorilor săi.</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-details-thumb">
                <img decoding="async" src={process.env.PUBLIC_URL + '/img/blog/dezvoltare-aplicatii-web.jpg'} alt="Programator foloseste 3 calculatoare pentru dezvoltarea de aplicatii web" loading="lazy" title="Dezvoltare aplicatii website" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ServiceDetailsItems1