import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';


function BlogDetailsContent2() {
  const [headings, setHeadings] = useState([]);
  const findHeadings = () => {
    const contentHeadings = document.querySelectorAll('.blog-details-content h4');
    const headingsArray = Array.from(contentHeadings).map((heading) => ({
      id: heading.id,
      text: heading.textContent,
    }));
    setHeadings(headingsArray);
  };

  useEffect(() => {
    findHeadings();
  }, []);

  const shareOnFacebook = () => {
    window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(window.location.href), '_blank');
  };

  const shareOnTwitter = () => {
    window.open('https://twitter.com/intent/tweet?url=' + encodeURIComponent(window.location.href), '_blank');
  };

  const shareOnPinterest = () => {
    window.open('https://www.pinterest.com/pin/create/button/?url=' + encodeURIComponent(window.location.href), '_blank');
  };

  const shareOnInstagram = () => {
    if (navigator.share) {
      navigator
        .share({
          url: window.location.href,
        })
        .then(() => console.log('Shared successfully'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
      console.log('Instagram sharing not supported');
    }
  };

  const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });


  return (
    <>
      <div className="blog-details-content">
        <center><h2>Importanța Digitalizării: Transformarea Țării în Era Tehnologiei</h2></center>
        <p>În era în care tehnologia evoluează cu o viteză uluitoare, digitalizarea devine tot mai importantă pentru dezvoltarea României. Procesul de digitalizare se referă la transformarea activităților tradiționale în procese digitale, cu ajutorul tehnologiei moderne. În acest articol, vom explora importanța digitalizării pentru România.  </p>
        <div className="details-thumb">
          <img decoding="async" src={process.env.PUBLIC_URL + "/img/blog/blog-digitalizare.png"} alt="Virus web design pentru dezvoltarea tehnologiei web" loading="lazy" title="Web design Timisoara" />
        </div>
        <nav>
          <h3>Cuprins</h3>
          {headings.map((heading, index) => (
            <li key={heading.id}>
              <a href={`#${heading.id}`}>{`${(index + 1).toString().padStart(2, '0')}. ${heading.text}`}</a>
            </li>
          ))}
        </nav>
        <div class="divider" ></div>
        <div className="blog-quote">
          <i className="fas fa-quote-left" />
          <p>In a world that's changing really quickly, the only strategy that is guaranteed to fail is not taking risks. <br></br>Mark Zuckerberg, CEO <a href="https://facebook.com/">Facebook</a></p>
        </div>
        <h4 id="Avantajele digitalizării">Avantajele digitalizării</h4>
        <div className="special">
          <p>dintre principalele beneficii ale digitalizării este îmbunătățirea productivității în toate domeniile. Prin digitalizare, procesele manuale și birocratice pot fi înlocuite cu soluții tehnologice inteligente. De exemplu, implementarea unor portaluri web în administrația publică poate accelera procesele de eliberare a documentelor și permiselor, reducând timpul și efortul necesar. În plus, digitalizarea poate simplifica fluxurile de lucru, reducând erorile umane din procese. Acest aspect conduce la o utilizare mai eficientă a resurselor, reducând, în același timp, costurile.</p>
          <div className="space"></div>
          <h4 id="Impact pozitiv asupra mediului">Impact pozitiv asupra mediului</h4>
          <p>Un alt avantaj important al digitalizării este impactul pozitiv asupra mediului înconjurător. În prezent, multe organizații și guverne din întreaga lume își propun să devină mai sustenabile din punct de vedere ecologic. Digitalizarea poate juca un rol esențial în această tranziție. De exemplu, înlocuirea documentelor fizice cu documente electronice reduce consumul de hârtie. De asemenea, munca de la distanță sau  posibilitatea achiziționării de produse online reduc necesitatea deplasărilor fizice. Prin digitalizare, România poate contribui la protejarea mediului înconjurător și la construirea unui viitor durabil și sustenabil.</p>
        </div>

        <img decoding="async" src={process.env.PUBLIC_URL + "/img/blog/legaturile-digitalizarii.png"} alt="Mana atinge informatii web design, holograma" loading="lazy" title="Agentie web design Romania" />
        <div class="space-20px"></div>
        <h4 id="Digitalizarea în scop comercial">Digitalizarea în scop comercial</h4>
        <p>Digitalizarea are un impact semnificativ asupra pieței muncii și a creșterii economice. Noile tehnologii și automatizarea duc la o schimbare a profilului locurilor de muncă. Pentru a rămâne relevanți în piața muncii, trebuie să se ne adaptăm noilor tendințe. Digitalizarea stimulează inovația și creativitatea, deschizând noi oportunități de afaceri și dezvoltare. România are un potențial enorm în industria tehnologică și digitală, iar investiția în aceste domenii poate duce la creșterea economică și crearea de locuri de muncă. </p>
        <p>Pentru o companie, indiferent de mărime, tranziția la mediul digital devine obligatorie. În primul rând, datorită expunerii oferite. În online, poți ajunge la un număr mult mai mare de oameni, direct interesați de produsul tău.  Într-o lume conectată digital, întreprinderile pot ajunge la piețe globale și pot extinde afacerile în mod rapid și eficient. Prin intermediul comerțului electronic, magazinele online pot vinde produse și servicii în întreaga lume, deschizând noi căi de creștere și dezvoltare economică.</p>
        <div className="tag-share">
          <div className="line-tag">
            <span>Tag:</span>
            <Link to={`${process.env.PUBLIC_URL}/serviciu-dezvoltare-web`} onClick={scrollTop}>web,</Link>
            <Link to={`${process.env.PUBLIC_URL}/serviciu-identitate-vizuala`} onClick={scrollTop}>branding,</Link>
            <Link to={`${process.env.PUBLIC_URL}/serviciu-social-media`} onClick={scrollTop}>social media,</Link>
          </div>
          <div className="share-blog">
            <span>Share On:</span>
            <ul className="social-share-blog">
              <li>
                <a href="#" onClick={shareOnFacebook}>
                  <i className="fab fa-facebook-f" />
                </a>
              </li>
              <li>
                <a href="#" onClick={shareOnTwitter}>
                  <i className="fab fa-twitter" />
                </a>
              </li>
              <li>
                <a href="#" onClick={shareOnPinterest}>
                  <i className="fab fa-pinterest-p" />
                </a>
              </li>
              <li>
                <a href="#" onClick={shareOnInstagram}>
                  <i className="fab fa-instagram" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div >
    </>
  );
}

export default BlogDetailsContent2;