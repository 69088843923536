import React from "react";
import AnimateLine from "../../common/AnimateLine";
import Breadcrumb from "../../common/Breadcrumb";
import SubscribArea from "../../common/SubscribArea";
import FaqWrap from "./FaqWrap";

function Faq() {
  return (
    <>
      <div className="ebrandy-wrap">
        <AnimateLine />
        <Breadcrumb pageName="Întrebări des întâlnite" pageTitle="Întrebări des întâlnite" />
        <FaqWrap />
        <SubscribArea />
      </div>
    </>
  );
}

export default Faq;
