import React from "react";
import AnimateLine from "../../../common/AnimateLine";
import Breadcrumb from "../../../common/Breadcrumb";
import SubscribArea from "../../../common/SubscribArea";
import ProjectDetailsWrap7 from "./ProjectDetailsWrap7";

function ProjectDetails7() {
  return (
    <>
      <div className="ebrandy-wrap">
        <AnimateLine />
        <Breadcrumb pageName="Refrigeration Panels North Texas" pageTitle="Refrigeration Panels North Texas" />
        <ProjectDetailsWrap7 />
        <SubscribArea />
      </div>
    </>
  );
}

export default ProjectDetails7;
