import React from 'react'
import ProjectDetailsContent6 from './ProjectDetailsContent6'
import RelatedProject6 from './RelatedProject6'
import SidebarWidget6 from './SidebarWidget6'

function ProjectDetailsWrap6() {
  return (
    <div>
      <div className="project-details sec-mar-top">

        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <ProjectDetailsContent6 />
            </div>
            <div className="col-lg-4">
              <SidebarWidget6 />
            </div>
            <RelatedProject6 />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectDetailsWrap6