import React from 'react'

function HistoryArea() {
  return (
    <>
      <section className="history-area sec-mar">
        <div className="container">
          <div className="title-wrap">
            <div className="sec-title">
              <span>De ce să alegi e-Brandy?</span>
              <h2>Aptitudini dobândite</h2>
            </div>
          </div>
          <div className="single-history">
            <div className="history wow animate fadeInLeft" data-wow-delay="200ms" data-wow-duration="1500ms">
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-thumb">
                <img decoding="async" src={process.env.PUBLIC_URL + '/img/eficienta-unei-agentii-de-web-design.jpg'} alt="Birou agentie web design cu laptop si rafturi cu dosare" loading="lazy" title="Birou agentie web design" copyright="https://www.freepik.com/ai/image-generator" />
              </div>
            </div>
            <div className="history wow animate fadeInRight" data-wow-delay="200ms" data-wow-duration="1500ms">
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-cnt">
                <div className="history-cnt-inner">
                  <span>Eficiență</span>
                  <h4>Ne recomandă fiecare proiect finalizat</h4>
                  <p>Oferim soluții bazate pe rezultate măsurabile, clare, vizibile. Îmbunătățim permanent fiecare campanie generată cu soluții bazate pe interacțiunea publicului. Progresul este scopul nostru principal, prin urmare, atingerea obiectivelor reprezintă, pentru noi, șansa de a ținti spre unele noi, mult mai ambițioase. </p>
                </div>
              </div>
            </div>
          </div>
          <div className="single-history">
            <div className="history wow animate fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-cnt">
                <div className="history-cnt-inner">
                  <span>Creativitate</span>
                  <h4>Oferim soluții venite direct din „afara cutiei“</h4>
                  <p>Prin integrarea informațiilor în serviciile noastre de digitalizare și branding, ne asigurăm că obținem o imagine completă și coerentă a afacerii dumneavoastră. Aceasta ne permite să dezvoltăm soluții personalizate, bazate pe date relevante, pentru a vă consolida prezența online și a vă crește notorietatea.</p>
                </div>
              </div>
            </div>
            <div className="history wow animate fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms">
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-thumb">
                <img decoding="async" src={process.env.PUBLIC_URL + '/img/creativitatea-unei-agentii-de-web-design.jpg'} alt="3 personaje desenate care dezvolta solutii personalizate web design" loading="lazy" title="Creativitatea unui web designer" copyright="https://www.freepik.com/ai/image-generator" />
              </div>
            </div>
          </div>
          <div className="single-history">
            <div className="history wow animate fadeInLeft" data-wow-delay="400ms" data-wow-duration="1500ms">
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-thumb">
                <img decoding="async" src={process.env.PUBLIC_URL + '/img/tenacitatea-unei-agentii-de-web-design.jpg'} alt="Doua persoane discuta analiza unui proiect web pentru luarea unei decizii" loading="lazy" title="Realizare plan dezvoltare website" copyright="https://www.freepik.com/ai/image-generator" />
              </div>
            </div>
            <div className="history wow animate fadeInRight" data-wow-delay="400ms" data-wow-duration="1500ms">
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-cnt">
                <div className="history-cnt-inner">
                  <span>Tenacitate</span>
                  <h4>Analizăm în detaliu fiecare aspect înainte de a lua o decizie</h4>
                  <p>Suntem perseverenți și răbdători în implementarea proiectului, având mereu ca obiectiv planul pe termen mediu sau lung. Ne plac rezultate sustenabile și imaginea ce nu poate fi contestată. Ne plac și performanțele, aspect ce ne obligă să fim răbdători și optimiști cu fiecare proiect implementat.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="single-history">
            <div className="history wow animate fadeInLeft" data-wow-delay="500ms" data-wow-duration="1500ms">
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-cnt">
                <div className="history-cnt-inner">
                  <span>Atenție</span>
                  <h4>Ne place să credem că nu ne scapă nimic</h4>
                  <p>Suntem o echipă de profesioniști specializați în comunicare și analiză de conținut. Punem la punct fiecare detaliu din planul tău și monitorizăm fiecare acțiune implementată. Oferim consultanță permanentă și rămânem aproape de tine în drumul spre progresul tău digital. </p>
                </div>
              </div>
            </div>
            <div className="history wow animate fadeInRight" data-wow-delay="500ms" data-wow-duration="1500ms">
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-thumb">
                <img decoding="async" src={process.env.PUBLIC_URL + '/img/atentia-unei-agentii-de-web-design.jpg'} alt="3 personaje discuta in fata unui laptop analiza competitorilor" loading="lazy" title="Localizare geografica web design Romania" copyright="https://www.freepik.com/ai/image-generator" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default HistoryArea