import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import SubscribArea from "../../common/SubscribArea";
import ServiceWrap from "./ServiceWrap";
import SEO from '@americanexpress/react-seo';


function Service() {
  return (
    <>      <SEO
      title="e-Brandy - Digitalizare și branding afacere | Agenție Promovare Online"
      description="Agentie Web Design Romania, Web Design Timisoara, Promovare online 100%"
      keywords={['agentie', 'web design']}
      siteUrl="https://e-brandy.ro"
      image={{
        src: 'https://e-brandy.ro/img/logo.png'
      }}
    />

      <div className="ebrandy-wrap">
        <Breadcrumb pageName="Servicii" pageTitle="Servicii" />
        <ServiceWrap />
        <SubscribArea />
      </div>
    </>
  );
}

export default Service;
