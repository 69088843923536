import React from 'react'
import { Link } from 'react-router-dom'

function ErrorWrapper() {
  return (
    <>
      <section className="back-to-home sec-pad">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8">
              <div className="error-wrapper">
                <img decoding="async" src={process.env.PUBLIC_URL + '/img/404.png'} alt="Error page image, error page" title="Error image" />
                <h3>Ne pare rău, nu putem găsi pagina pe care o căutați!</h3>
                <p>Reveniți înapoi la pagina principală, pentru orice informație suplimentară, ne puteți contacta pe adresa de email sau la telefon. </p>
                <div className="home-btn">
                  <Link to={`${process.env.PUBLIC_URL}/`} onClick={window.scrollTo({ top: 0, behavior: "smooth" })}><i className="bi bi-house-door" />Înapoi la pagina principală</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ErrorWrapper