import React from "react";
import { Link } from "react-router-dom";
import ServiceDetailsItems6 from "./ServiceDetailsItems6";

function ServiceDetailsWrap6() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" })
  return (
    <>
      <div className="service-details sec-mar-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="service-details-content">
                <img decoding="async" src={process.env.PUBLIC_URL + '/img/blog/social-media-serviciu.jpg'} alt="Simboluri social media Timisoara, like, smiley face, inima, chat, basket" loading="lazy" title="Serviciu Social Media Timisoara" />
                <h3>
                  <i>
                    <img
                      src={process.env.PUBLIC_URL + '/img/icons/service-icon-2.png'}
                      alt="service icon images"
                    />
                  </i>
                  Social Media
                </h3>
                <p>
                  Administrarea eficientă a social media este esențială pentru succesul unei afaceri în era digitală. Prin intermediul unei prezențe active și bine gestionate pe platformele sociale, afacerea poate atinge un public larg și diversificat. Administrarea social media permite comunicarea directă cu clienții și potențialii clienți, construind și menținând relații puternice cu aceștia. Este o modalitate eficientă de a promova produsele, serviciile și brandul afacerii și de a atrage atenția publicului într-un mod autentic și captivant.
                </p>
                <p>
                  Administrarea social media contribuie la creșterea vizibilității și notorietății afacerii. Prin intermediul platformelor sociale, afacerea poate fi descoperită de un număr mare de oameni și poate construi o imagine puternică și de încredere. Prin postări relevante și conținut de calitate, afacerea poate atrage și angaja audiența, construind o comunitate loială de clienți și susținători. De asemenea, administrarea social media oferă oportunități de promovare și de creștere a conștientizării brandului într-un mod eficient și accesibil.
                </p>
                <p>
                  Administrarea social media permite monitorizarea și analiza performanței și a impactului campaniilor de marketing. Prin intermediul instrumentelor de analiză, afacerea poate evalua rezultatele obținute și poate identifica strategiile și tactici eficiente. Aceasta permite ajustarea și optimizarea activității pe social media pentru a obține rezultate mai bune și a atinge obiectivele de afaceri. Administrarea social media oferă, de asemenea, posibilitatea de a răspunde rapid la feedback-ul și comentariile primite, gestionând reputația afacerii în mediul online și consolidând încrederea clienților.{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar-widget">
                <h4>Suntem alături de tine în următoarele:</h4>
                <ul className="category">
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/serviciu-dezvoltare-web`} onClick={scrollTop}>
                      Dezvoltare web
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/serviciu-social-media`} onClick={scrollTop}>
                      Social Media
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/serviciu-mentenanta-it`} onClick={scrollTop}>
                      Mentenanță IT
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/serviciu-servicii-foto-video`} onClick={scrollTop}>
                      Servicii Foto-Video
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/serviciu-identitate-vizuala`} onClick={scrollTop}>
                      Logo și identitate vizuală
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/serviciu-marketing-digital-seo`} onClick={scrollTop}>
                      Marketing Digital-SEO
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="sidebar-banner">
                <img src={process.env.PUBLIC_URL + "/img/widget-banner-bg.jpg"} alt="Detalii contact pentru servicii digitalizare si branding" />
                <div className="banner-inner">
                  <h3>
                    <a href="tel:+40721126226">
                      Prețuri începând <span>de la</span><span> +4 0721 126 226</span><img className="angle" src={process.env.PUBLIC_URL + "/img/arrow-angle.png"} alt="Directie sageata catre detalii de contact pentru servicii de branding si digitalizare" />
                      <span> 50euro</span> </a>
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <ServiceDetailsItems6 />
        </div>
      </div>
    </>
  );
}

export default ServiceDetailsWrap6;
