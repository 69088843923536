import React from "react";
import AnimateLine from "../../../common/AnimateLine";
import Breadcrumb from "../../../common/Breadcrumb";
import SubscribArea from "../../../common/SubscribArea";
import ProjectDetailsWrap8 from "../Modirom/ProjectDetailsWrap8";

function ProjectDetails8() {
  return (
    <>
      <div className="ebrandy-wrap">
        <AnimateLine />
        <Breadcrumb pageName="Modirom" pageTitle="Modirom" />
        <ProjectDetailsWrap8 />
        <SubscribArea />
      </div>
    </>
  );
}

export default ProjectDetails8;
