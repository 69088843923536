import React from 'react'
import { Link } from 'react-router-dom'
import "swiper/css/autoplay";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {

  Autoplay,
  Navigation,
  EffectFade,
  Pagination
} from "swiper";
SwiperCore.use([Navigation, Autoplay, EffectFade, Pagination,]);
function Testimonial() {
  const testimonialSlider = {
    loop: true,
    slidesPerView: 2,
    centeredSlides: true,
    duration: 5000,
    speed: 1000,
    autoplay: {
      delay: 3500,
      isableOnInteraction: true,
    },
    spaceBetween: 30,
    slideToClickedSlide: true,

    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 2,
      },
      1400: {
        slidesPerView: 2,
      },
      1800: {
        slidesPerView: 2,
      }
    }
  }
  return (
    <>
      <section className="testimonial-area">
        <div className="container-fluid p-0">
          <div className="title-wrap">
            <div className="sec-title white">
              <span>Testimoniale</span>
              <h2>Recenzii clienții</h2>
              <p>Poate că nu suntem perfecți, totuși câțiva dintre cei care au colaborat cu e-Brandy spun următoarele:</p>
            </div>
          </div>
          <Swiper {...testimonialSlider} className="swiper testimonial-slider">
            <div className="swiper-wrapper">
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Alin Costache</h5>
                  <span>Administrator BC Volumetrisch</span>
                  <div className="stars">
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                  </div>
                  <p>Locuiesc in Germania de cativa ani si mi-am deschis aici o mica afacere cu litere volumetrice si decor evenimente. Am avut nevoie de un website unde sa-mi pot publica produsele si o strategie de viitor. Multumesc lui Alexandru pentru serviciile excelente!</p>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Alessandro Acri</h5>
                  <span>Fondator și CEO MTA Group</span>
                  <div className="stars">
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                  </div>
                  <p>Sunt fondatorul MTA Group Career Management, calatoresc prin toata Europa pentru descoperirea tinerelor talente sportive. Am avut nevoie de un website publcitar unde sa pot publica trasferurile pe care le efectuez si asta am primit. Mihai este foarte priceput la comunicarea cu publicul si scrierea de bloguri sportive.  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Marcel Ienci</h5>
                  <span>Administrator ONG Anais Ienci învinge epilepsia</span>
                  <div className="stars">
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                  </div>
                  <p>Alexandru si Mihai au decis sa ma ajute in mod gratuit pentru crearea unui site. Colaborarea alaturi de ei inca continua si am lucrat impreuna pentru site. Au avut rabdare si au fost foarte deschisi in toata treaba. Recomand!</p>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Andrei Dincă</h5>
                  <span>Manager Ericco Solutuions</span>
                  <div className="stars">
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                  </div>
                  <p>Colaborarea pentru site cu cei de la e-Brandy a decurs exact cum a fost prezentata de ei la inceput. Am reusit sa vorbim, sa imi explice si sa imi arate pana am ajuns la ceva care mi-a placut. Apoi, intr-un timp foarte scurt au pus dorintele mele in mediul online in online. Recomand cu incredere.</p>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Constantin Tanasciuc</h5>
                  <span>Administrator Modirom </span>
                  <div className="stars">
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                  </div>
                  <p></p>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Nelu Cristian</h5>
                  <span>Administrator Refrigeration Panels North Texas </span>
                  <div className="stars">
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                  </div>
                  <p></p>
                </div>
              </SwiperSlide>
            </div>
          </Swiper>
          <div className="swiper-button-next" />
          <div className="swiper-button-prev" />
        </div>
      </section>
    </>
  )
}

export default Testimonial