import React from "react";
import AnimateLine from "../../../common/AnimateLine";
import Breadcrumb from "../../../common/Breadcrumb";
import SubscribArea from "../../../common/SubscribArea";
import ProjectDetailsWrap4 from "../FSGStudio/ProjectDetailsWrap4";

function ProjectDetails4() {
  return (
    <>
      <div className="ebrandy-wrap">
        <AnimateLine />
        <Breadcrumb pageName="FSG Studio" pageTitle="FSG Studio" />
        <ProjectDetailsWrap4 />
        <SubscribArea />
      </div>
    </>
  );
}

export default ProjectDetails4;
