import React from 'react'
import ProjectDetailsContent4 from '../FSGStudio/ProjectDetailsContent4'
import RelatedProject4 from '../FSGStudio/RelatedProject4'
import SidebarWidget4 from '../FSGStudio/SidebarWidget4'

function ProjectDetailsWrap4() {
  return (
    <div>
      <div className="project-details sec-mar-top">

        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <ProjectDetailsContent4 />
            </div>
            <div className="col-lg-4">
              <SidebarWidget4 />
            </div>
            <RelatedProject4 />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectDetailsWrap4