import React from 'react'
import { Link } from 'react-router-dom'
import "swiper/css/autoplay";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper";
SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);

function ProjectDetailsContent8() {
  const projectSlider = {
    loop: true,
    slidesPerView: 1,
    navigation: {
      nextEl: ".swiper-button-next-c",
      prevEl: ".swiper-button-prev-c",
    }
  }
  return (
    <>
      <div className="project-details-content">
        <div className="project-thumb">
          <img decoding="async" src={process.env.PUBLIC_URL + '/img/project/modirom.png'} alt="Modirom Timisoara logo, link" loading="lazy" title="Modirom Logo" />
          <div className="tag">
            <a href="https://modirom.ro/">Website Modirom</a>
          </div>
        </div>
        <h3>Modirom</h3>
        <p>Colaborarea noastră de lungă durată cu Constantin Tanasciuc, unul dintre cei mai vechi și apropiați clienți ai noștri, este o poveste de succes ce a adus rezultate remarcabile pentru compania sa, Modirom - Materiale de Construcții.</p>
        <div className="clinet-need">
          <h4>Nevoia clientului:</h4>
          <p>Colaborarea noastră este în desfășurare și ne bucurăm să continuăm să lucrăm alături de Constantin pentru a perfecționa și a dezvolta continuu strategiile de promovare online. Rezultatele bune obținute până acum sunt doar începutul, iar angajamentul nostru față de succesul companiei Modirom rămâne constant.</p>
          <p>Suntem recunoscători pentru încrederea pe care Constantin ne-a acordat-o de-a lungul timpului și suntem mândri să facem parte din echipa sa, contribuind la creșterea și consolidarea afacerii sale în mediul online.</p>
        </div>
      </div>

    </>
  )
}

export default ProjectDetailsContent8