import React from 'react'
import { Link } from 'react-router-dom'
import "swiper/css/autoplay";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper";
SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);

function ProjectDetailsContent3() {
  const projectSlider = {
    loop: true,
    slidesPerView: 1,
    navigation: {
      nextEl: ".swiper-button-next-c",
      prevEl: ".swiper-button-prev-c",
    }
  }
  return (
    <>
      <div className="project-details-content">
        <div className="project-thumb">
          <img decoding="async" src={process.env.PUBLIC_URL + '/img/project/ericco.jpg'} alt="Ericco Logo, link" loading="lazy" title="Ericco" />
          <div className="tag">
            <a href="https://ericco.ro">Website Ericco Solutions</a>
          </div>
        </div>
        <h3>Ericco Solutions</h3>
        <p>Afacerea lui Andrei atingea mai puțin piața din România, acesta fiind stabilit în Austria. Prin urmare, Andrei a solicitat un website, prin care să țină publicul interesat la curent cu serviciile prestate. </p>
        <p>Ca workflow, colaboararea a început printr-o discuție, pentru a înțelege afacerea lui Andrei, serviciile oferite și publicul la care dorește să ajungă.</p>
        <div className="clinet-need">
          <h4>Stabilirea direcției digitale</h4>
          <p>Ulterior, am stabilit ce tip de brand vrea să fie, paleta de culori și tipul de design dorit. În timpul desfășurării proiectului am fost în contact permanent cu Andrei, aspect ce a făcut colaboararea să se extendă și în realizarea câtorva materiale de promovare offline, cum ar fi cărți de vizită.</p>
          <p>Mulțumim Andrei pentru încrederea acordată și sperăm să putem colabora pe viitor. </p>
        </div>

      </div>

    </>
  )
}

export default ProjectDetailsContent3