import React from 'react'
import { Link } from 'react-router-dom'

function LatesPost() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" })
  return (
    <>
      <section className="blog-area">
        <div className="container">
          <div className="title-wrap -6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
            <div className="sec-title">
              <span>e-Brandy Blog</span>
              <h2>Ultimele Postări</h2>
              <p>Fii la curent cu ultimele știri în materie de dezvoltare website, dezvoltare web design, digitalizare afacere și multe altele:</p>
            </div>
          </div>
          <div className="row gy-4">
            <div className="col-md-6 col-lg-4 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
              <div className="single-blog">
                <div className="blog-thumb">
                  <Link to={`${process.env.PUBLIC_URL}/blog`} onClick={scrollTop}><img decoding="async" src={process.env.PUBLIC_URL + '/img/blog/blog-branding-romania.png'} alt="Blog recent web design Romania" loading="lazy" title="Branding Romania" /></Link>
                  <div className="tag">
                    <Link to={`${process.env.PUBLIC_URL}/proiecte`} onClick={scrollTop}>Branding în România</Link>
                  </div>
                </div>
                <div className="blog-inner">
                  <div className="author-date">
                    <Link to={"#"}>Servicii web design</Link>
                    <Link to={"#"}>31.05.2022</Link>
                  </div>
                  <h4><Link to={`${process.env.PUBLIC_URL}/blog`} onClick={scrollTop}>România evoluează tot mai mult în domeniul digital.</Link></h4>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow animate fadeInUp" data-wow-delay="400ms" data-wow-duration="1500ms">
              <div className="single-blog">
                <div className="blog-thumb">
                  <Link to={`${process.env.PUBLIC_URL}/blog`} onClick={scrollTop}><img decoding="async" src={process.env.PUBLIC_URL + '/img/blog/blog-digitalizarea-in-institutii-publice.png'} alt="Blog recent e-Brandy web design Timisoara" loading="lazy" title="Dezvoltare website institutii publice" /></Link>
                  <div className="tag">
                    <Link to={`${process.env.PUBLIC_URL}/proiecte`} onClick={scrollTop}>Dezvoltare website instituții publice</Link>
                  </div>
                </div>
                <div className="blog-inner">
                  <div className="author-date">
                    <Link to={"#"}>România Digitală</Link>
                    <Link to={"#"}>15.01.2023</Link>
                  </div>
                  <h4><Link to={`${process.env.PUBLIC_URL}/blog`} onClick={scrollTop}>Automatizarea instituțiilor publice, dezvoltare smart.</Link></h4>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow animated fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
              <div className="single-blog">
                <div className="blog-thumb">
                  <Link to={`${process.env.PUBLIC_URL}/blog`} onClick={scrollTop}><img decoding="async" src={process.env.PUBLIC_URL + "/img/blog/blog-respectarea-legislatiei.png"} alt="Blog recent e-Brandy respectare legislatie website" loading="lazy" title="Respectarea legislatiei online" /></Link>
                  <div className="tag">
                    <Link to={`${process.env.PUBLIC_URL}/proiecte`} onClick={scrollTop}>Respectarea legislației</Link>
                  </div>
                </div>
                <div className="blog-inner">
                  <div className="author-date">
                    <Link to={"#"}>Securitate și legislație online</Link>
                    <Link to={"#"}>15.08.2022</Link>
                  </div>
                  <h4><Link to={`${process.env.PUBLIC_URL}/blog`} onClick={scrollTop}>Respectă legile din România, asigură afacerea în online.</Link></h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default LatesPost