import React from 'react'
import { Link } from 'react-router-dom'

function SingleBlog(props) {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" })
  return (
    <>
      <div className="col-md-6">
        <div className="single-blog">
          <div className="blog-thumb">
            <Link to={props.url} onClick={scrollTop}><img decoding="async" src={`${process.env.PUBLIC_URL} ${props.image}`} alt="Blog agentie web design Romania" /></Link>
            <div className="tag">
              <Link to={props.url} onClick={scrollTop}>{props.title}</Link>
            </div>
          </div>
          <div className="blog-inner">
            <div className="author-date">
              <Link to={"#"}>Realizat de e-Brandy </Link>
              <Link to={"#"}>{props.date}</Link>
            </div>
            <h4><Link to={props.url} onClick={scrollTop}>{props.details}</Link></h4>
          </div>
        </div>
      </div>
    </>
  )
}

export default SingleBlog