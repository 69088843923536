import React from 'react'
import CatagoryWidget from "../../blog/CatagoryWidget";
import BannerWidget from "../../blog/BannerWidget";
import BlogDetailsContent1 from '../Creareaidentitatiivizuale/BlogDetailsContent1';


function BlogDetailsWrap1() {
  return (
    <>
      <div className="blog-details-area sec-mar-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <BlogDetailsContent1 />
            </div>
            <div className="col-lg-4">
              <CatagoryWidget />
              <BannerWidget />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BlogDetailsWrap1