import React from 'react'

function WhyChooseUs() {
  return (
    <>
      <section className="why-choose sec-mar wow animate fadeIn" data-wow-delay="200ms" data-wow-duration="1500ms">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="why-choose-left">
                <div className="choose-banner1">
                  <img decoding="async" src={process.env.PUBLIC_URL + "/img/e-brandy-despre-noi.jpg"} alt="Echipa e-Brandy - Agentie web design Romania" loading="lazy" title="Agentie Web Design Romania" />
                  <img decoding="async" src={process.env.PUBLIC_URL + "/img/designer-grafic.jpg"} alt="Grafic artist realizeaza schite pentru designul web" loading="lazy" title="Designer web Timisoara" />
                </div>
                <div className="choose-banner2">
                  <img decoding="async" src={process.env.PUBLIC_URL + '/img/e-brandy-despre-noi-1.jpg'} loading="lazy" alt="Membru echipa web design e-Brandy prezinta servicii web oferite" title="Web design Timisoara" />
                </div>
                <div className="years">
                  <h5>Agenție digitalizare și branding Timisoara</h5>
                  <span>Agentie web design Romania</span>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="why-choose-right">
                <div className="sec-title layout2">
                  <span>Ce spun clienții?</span>
                  <h2>Știm cum să păstrăm discreția și profesionalismul.</h2>
                </div>
                <div className="counter-boxes">
                  <div className="count-box">
                    <span className="counter">37</span><sup>+</sup>
                    <h5>Proiecte finalizate</h5>
                  </div>
                  <div className="count-box">
                    <span className="counter">23</span><sup>+</sup>
                    <h5>Clienți satisfăcuți</h5>
                  </div>
                  <div className="count-box">
                    <span className="counter">6</span><sup>+</sup>
                    <h5>Echipă</h5>
                  </div>
                </div>
                <p>Suntem o echipă formată din tineri pasionați de comunicare. Scopul nostru principal este reprezentat de inovație, iar eficiența și abordările autentice pot fi descoperite în fiecare proiect implementat până în acest moment. </p>
                <div className="buttons-group">
                  <span>24/7 Suport</span>
                  <span>Digitalizare</span>
                  <span>Branding</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default WhyChooseUs