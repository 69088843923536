import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function BlogDetailsContent6() {
  const [headings, setHeadings] = useState([]);
  const findHeadings = () => {
    const contentHeadings = document.querySelectorAll('.blog-details-content h4');
    const headingsArray = Array.from(contentHeadings).map((heading) => ({
      id: heading.id,
      text: heading.textContent,
    }));
    setHeadings(headingsArray);
  };

  useEffect(() => {
    findHeadings();
  }, []);

  const shareOnFacebook = () => {
    window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(window.location.href), '_blank');
  };

  const shareOnTwitter = () => {
    window.open('https://twitter.com/intent/tweet?url=' + encodeURIComponent(window.location.href), '_blank');
  };

  const shareOnPinterest = () => {
    window.open('https://www.pinterest.com/pin/create/button/?url=' + encodeURIComponent(window.location.href), '_blank');
  };

  const shareOnInstagram = () => {
    if (navigator.share) {
      navigator
        .share({
          url: window.location.href,
        })
        .then(() => console.log('Shared successfully'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
      console.log('Instagram sharing not supported');
    }
  };

  const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

  return (
    <>
      <div className="blog-details-content">
        <center><h2>Modalități prin care îți poți afirma afacerea în mediul online</h2></center>
        <p>În era digitală, afirmația în mediul online este esențială pentru a-ți construi o afacere de succes. Cu atât de multe informații disponibile online, abilitatea de a te face remarcat ca afacere este crucială pentru a atrage noi clienți și a construi un public fidel. Aici sunt câteva modalități prin care îți poți afirma afacerea în mediul online.</p>
        <div className="details-thumb">
          <img decoding="async" src={process.env.PUBLIC_URL + "/img/blog/blog-treptat-in-online.png"} alt="Spatele unui robot merge si arata directia afirmarii in online" loading="lazy" title="Pasi de parcurs pana la afirmarea online" />
        </div>
        <nav>
          <h3>Cuprins</h3>
          {headings.map((heading, index) => (
            <li key={heading.id}>
              <a href={`#${heading.id}`}>{`${(index + 1).toString().padStart(2, '0')}. ${heading.text}`}</a>
            </li>
          ))}
        </nav>
        <div className='divider'></div>
        <div className="blog-quote">
          <i className="fas fa-quote-left" />
          <p>O publicitate bună declanșează o emoție în tine. Are un scop, atinge un nerv, iar asta provoacă o reacție.<br></br> - David Droga</p>
        </div>
        <h4 id="Creaza un website sau un magazin online">Creaza un website sau un magazin online</h4>
        <div className="special">
          <p>Crearea unui website sau a unui magazin online este esențial pentru a-ți afirma afacerea în mediul online. În prezent, majoritatea oamenilor apelează la internet pentru a căuta informații despre afacerile locale și pentru a face cumpărături online. Asigură-te că website-ul tău este curat, ușor de navigat și conține informații importante despre afacerea ta, precum și produsele și serviciile oferite. <a href="http://e-brandy.ro/serviciu-mentenanta-it">Întreținerea și actualizarea website-ului tau</a> în mod regulat poate consolida poziția ta în motoarele de căutare și poate îmbunătăți experiența utilizatorilor.</p>
        </div>
        <h4 id="Utilizeaza rețelele sociale">Utilizeaza rețelele sociale</h4>
        <div className="special">
          <p><a href="http://e-brandy.ro/serviciu-social-media">Rețelele sociale </a>sunt o modalitate excelentă de a-ți afirma afacerea online. Acestea îți permit să îți promovezi produsele și serviciile în mod eficient și să construiești o comunitate online în jurul brandului tău. Pe lângă stabilirea unei prezențe consistente pe platformele de socializare, poți folosi și campaniile publicitare pe aceste platforme pentru a ajunge la un public mai mare.</p>
        </div>
        <div class="space"></div>
        <center><img decoding="async" src={process.env.PUBLIC_URL + "/img/blog/paseste-online-1.png"} alt="Doua persoane inconjurati metode de afirmare magazin online" loading="lazy" title="Strategie, web design magazin online" /></center>
        <div class="space"></div>
        <h4 id="Folosește marketingul prin email">Folosește marketingul prin email</h4>
        <div className="special">
          <p>Marketingul prin email poate fi, de asemenea, o modalitate eficientă de a-ți afirma afacerea online. Prin promovarea produselor sau ofertelor speciale prin intermediul email-urilor personalizate, poți ajunge la o audiență largă și să creezi relații mai strânse cu clienții tăi. Înainte să începi campaniile de marketing prin email, asigură-te că aduni liste de email-uri de calitate și că știi cum să personalizezi mesajele în mod corespunzător.</p>
        </div>
        <h4 id="Colaborează cu influenceri și bloggeri">Colaborează cu influenceri și bloggeri</h4>
        <div className="special">
          <p>O altă modalitate de a-ți afirma afacerea online este prin colaborarea cu influenceri și bloggeri renumiți în nișa ta de piață. Aceștia pot promova produsele și serviciile tale prin intermediul rețelelor lor de socializare și prin articole pe blogurile lor. Cu ajutorul acestei colaborări, poți extinde foarte mult aria de acoperire a brandului tău și să creezi un public mai mare de loialiți.</p>
        </div>
        <div class="space"></div>
        <center><img decoding="async" src={process.env.PUBLIC_URL + "/img/blog/paseste-online-2.png"} alt="Trei persoane analizeaza strategii marketing online" loading="lazy" title="Realizare magazin online" copyright="https://freepik.com/" /></center>
        <div className='divider'></div>
        <p>În concluzie, există multe modalități prin care îți poți afirma afacerea în mediul online. Crearea unui website sau a unui magazin online, utilizarea rețelelor sociale, marketingul prin email și colaborarea cu influenceri și bloggeri sunt doar câteva dintre modalitățile tradiționale de a atrage clienți noi și de a construi o comunitate de loialiți. Prin înțelegerea publicului tău țintă și crearea unui mesaj coerent pentru brandul tău, poți reuși să construiești o afacere solidă și proeminentă în mediul online.</p>
        <div className="tag-share">
          <div className="line-tag">
            <span>Tag:</span>
            <Link to={`${process.env.PUBLIC_URL}/serviciu-dezvoltare-web`} onClick={scrollTop}>web,</Link>
            <Link to={`${process.env.PUBLIC_URL}/serviciu-identitate-vizuala`} onClick={scrollTop}>branding,</Link>
            <Link to={`${process.env.PUBLIC_URL}/serviciu-social-media`} onClick={scrollTop}>social media,</Link>
          </div>
          <div className="share-blog">
            <span>Share On:</span>
            <ul className="social-share-blog">
              <li>
                <a href="#" onClick={shareOnFacebook}>
                  <i className="fab fa-facebook-f" />
                </a>
              </li>
              <li>
                <a href="#" onClick={shareOnTwitter}>
                  <i className="fab fa-twitter" />
                </a>
              </li>
              <li>
                <a href="#" onClick={shareOnPinterest}>
                  <i className="fab fa-pinterest-p" />
                </a>
              </li>
              <li>
                <a href="#" onClick={shareOnInstagram}>
                  <i className="fab fa-instagram" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div >
    </>
  );
}

export default BlogDetailsContent6;