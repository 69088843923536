import React from "react";
import AnimateLine from "../../../common/AnimateLine";
import Breadcrumb from "../../../common/Breadcrumb";
import SubscribArea from "../../../common/SubscribArea";
import ProjectDetailsWrap10 from "../TheSquatBabe/ProjectDetailsWrap10";

function ProjectDetails10() {
  return (
    <>
      <div className="ebrandy-wrap">
        <AnimateLine />
        <Breadcrumb pageName="The Squat Babe" pageTitle="The Squat Babe" />
        <ProjectDetailsWrap10 />
        <SubscribArea />
      </div>
    </>
  );
}

export default ProjectDetails10;
