import React from 'react'
import AnimateLine from '../../common/AnimateLine'
import SubscribArea from '../../common/SubscribArea'
import ProjectWrap from '../project/ProjectWrap'
import TeamWrap from '../team/TeamWrap'
import AboutArea from './AboutArea'
import FeaturesArea from './FeaturesArea'
import HeroArea from './HeroArea'
import LatesPost from './LatesPost'
import PartnarArea from './PartnarArea'
import ServiceArea from './ServiceArea'
import Testimonial from './Testimonial'
import SEO from '@americanexpress/react-seo';

function HomePageOne() {
  return (
    <>
      <SEO
        title="Digitalizare și branding afacere | Agenție Promovare Online"
        description="Agentie Web Design Romania, Web Design Timisoara, Promovare online 100%"
        keywords={['agentie', 'web design']}
        siteUrl="https://e-brandy.ro"
        image={{
          src: 'https://e-brandy.ro/img/logo.png'
        }}
      />
      <div className="ebrandy-wrap">
        <AnimateLine />
        <HeroArea />
        <ServiceArea />
        <AboutArea image="/img/logo-dark.svg" />
        <FeaturesArea />
        <ProjectWrap />
        <PartnarArea />
        <Testimonial />
        <TeamWrap />
        <LatesPost />
        <SubscribArea />
      </div>
    </>
  )
}

export default HomePageOne