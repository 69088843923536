import React from 'react'

function ServiceDetailsItems2() {
  return (
    <>
      <div className="service-items sec-mar-top">
        <div className="service-details-item">
          <div className="row">
            <div className="col-lg-6">
              <div className="single-details">
                <h4><span>01</span>Cercetare și Analiză</h4>
                <p>În această etapă, se efectuează cercetări despre industria și piața în care activează clientul, precum și despre concurența existentă. Se identifică valorile, misiunea și viziunea afacerii pentru a crea o identitate vizuală coerentă. Se analizează preferințele și cerințele clientului pentru a înțelege direcția dorită pentru logo și identitatea vizuală.</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-details-thumb">
                <img decoding="async" src={process.env.PUBLIC_URL + '/img/blog/cercetare-si-analiza.jpg'} alt="Paleta de culori pentru creare identitate vizuala" loading="lazy" title="Cercetare si analiza logo" copyright="https://freepik.com/" />
              </div>
            </div>
          </div>
        </div>
        <div className="service-details-item">
          <div className="row">
            <div className="col-lg-6 or-2">
              <div className="single-details-thumb">
                <img src={process.env.PUBLIC_URL + '/img/blog/conceptualizare-si-design.jpg'} alt="6 persoane in procesul de conceptualizare si design website" loading="lazy" title="Conceptualizare si design web" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-details">
                <h4><span>02</span>Conceptualizare și Design </h4>
                <p> Pe baza cercetării și analizei, se explorează multiple direcții de design și se dezvoltă concepte inițiale pentru logo și identitatea vizuală. Se lucrează la crearea formelor, culorilor, tipografiilor și elementelor grafice care vor reprezenta și diferenția afacerea clientului. Se creează diverse variante și se comunică cu clientul pentru a obține feedback și a ajusta designul în consecință.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="service-details-item">
          <div className="row">
            <div className="col-lg-6">
              <div className="single-details">
                <h4><span>03</span>Rafinare și Livrare </h4>
                <p>După ce se prezintă conceptele inițiale clientului, se obține feedback și se lucrează la rafinarea designului. Se ajustează detaliile, se optimizează logo-ul și se finalizează identitatea vizuală. Se creează o gamă completă de materiale de identitate, precum carduri de vizită, șabloane de prezentare și alte elemente necesare. În final, se livrează toate fișierele necesare pentru utilizare în diverse medii și formate.</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-details-thumb">
                <img decoding="async" src={process.env.PUBLIC_URL + '/img/blog/rafinare-si-design.jpg'} alt="Birou agentie web design Timisoara" loading="lazy" title="Rafinare identitate vizuala si livrare" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ServiceDetailsItems2