import React from 'react'
import ProjectDetailsContent5 from '../ImpreunapentruAnais/ProjectDetailsContent5'
import RelatedProject5 from '../ImpreunapentruAnais/RelatedProject5'
import SidebarWidget5 from '../ImpreunapentruAnais/SidebarWidget5'

function ProjectDetailsWrap5() {
  return (
    <div>
      <div className="project-details sec-mar-top">

        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <ProjectDetailsContent5 />
            </div>
            <div className="col-lg-4">
              <SidebarWidget5 />
            </div>
            <RelatedProject5 />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectDetailsWrap5