import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Layout from "./components/App";
import MainLayout from "./components/layout/MainLayout";
import About from "./components/page/about/About";
import BlogSidebar from "./components/page/blog/BlogSidebar";
import Contact from "./components/page/contact/Contact";
import ErrorPage from "./components/page/error/ErrorPage";
import Faq from "./components/page/faq/Faq";
import Pricing from "./components/page/pricing/Pricing";
import Project from "./components/page/project/Project";
import Service from "./components/page/service/Service";
import DezvoltareWeb from "./components/page/Servicii/DezvoltareWeb/ServiceDetails1";
import IdentitateVizuala from "./components/page/Servicii/IdentitateVizuala/ServiceDetails2";
import MarketingDigitalSEO from "./components/page/Servicii/MarketingDigitalSEO/ServiceDetails3";
import MentenantaIT from "./components/page/Servicii/MentenantaIT/ServiceDetails4";
import ServiciiFotoVideo from "./components/page/Servicii/ServiciiFotoVideo/ServiceDetails5";
import SocialMedia from "./components/page/Servicii/SocialMedia/ServiceDetails6";
import Team from "./components/page/team/Team";
import BCVolumetrisch from "./components/page/Proiecte/BCVolumetrisch/ProjectDetails1";
import CabanaRausor from "./components/page/Proiecte/CabanaRausor/ProjectDetails2";
import EriccoSolutions from "./components/page/Proiecte/EriccoSolutions/ProjectDetails3";
import FSGStudio from "./components/page/Proiecte/FSGStudio/ProjectDetails4";
import Impreunapentruanais from "./components/page/Proiecte/ImpreunapentruAnais/ProjectDetails5";
import MTAGroup from "./components/page/Proiecte/MTAGroup/ProjectDetails6";
import RefrigerationPanelsNT from "./components/page/Proiecte/RefrigerationPanelsNT/ProjectDetails7";
import Modirom from "./components/page/Proiecte/Modirom/ProjectDetails8";
import KlinkerVest from "./components/page/Proiecte/KlinkerVest/ProjectDetails9";
import TheSquatBabe from "./components/page/Proiecte/TheSquatBabe/ProjectDetails10";
import TSALaw from "./components/page/Proiecte/TSALaw/ProjectDetails11";
import CFRTimisoara from "./components/page/Proiecte/CFRTimisoara/ProjectDetails12";
import CSCSacalaz from "./components/page/Proiecte/CSCSacalaz/ProjectDetails13";





import BrandinginRomania from "./components/page/Bloguri/BrandinginRomania/BlogDetails";
import Creareaidentitatiivizuale from "./components/page/Bloguri/Creareaidentitatiivizuale/BlogDetails1";
import Digitalizare from "./components/page/Bloguri/Digitalizare/BlogDetails2";
import Digitalizareineducatie from "./components/page/Bloguri/Digitalizareineducatie/BlogDetails3";
import Digitalizareinstitutiipublice from "./components/page/Bloguri/Digitalizareinstitutiipublice/BlogDetails4";
import Modalitatideafirmare from "./components/page/Bloguri/Modalitatideafirmare/BlogDetails6";
import Etapeledigitalizarii from "./components/page/Bloguri/Etapeledigitalizarii/BlogDetails5";
import Respectarealegislatiei from "./components/page/Bloguri/Respectarealegislatiei/BlogDetails7";
import Admin from "./components/admin/admin";
import { CookiesProvider } from 'react-cookie';
import CookieConsent, { Cookies } from "react-cookie-consent";

import { firebaseApp } from "./firebase.config";
import { getAnalytics, logEvent } from "firebase/analytics";

import "./index.css"


//Default Warniing Error Hide
console.log = console.warn = console.error = () => { };

/*
=>version : 0.1
=>Event : Rendering al content to web
=>Action: define all routes and page
@return HTML
*/

function Root() {

  React.useEffect(() => {
    const analytics = getAnalytics(firebaseApp);
    //log event app initialized
    logEvent(analytics, 'app_initialized');


  }, []);




  return <>
    <CookiesProvider defaultSetCookies={{ path: '/' }}>
      <BrowserRouter basename="/">
        <Switch>
          {/*main layout*/}
          <Route exact path="/" component={MainLayout} />
          <Route exact path="/admin" component={Admin} />
          <Layout>
            {/* <Route
          exact
          path={`${process.env.PUBLIC_URL}/about-us`}
          component={AboutPage}
        /> */}
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/despre-noi/`}
              component={About}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/contact/`}
              component={Contact}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/error/`}
              component={ErrorPage}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/blog/`}
              component={BlogSidebar}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/servicii/`}
              component={Service}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/proiecte/`}
              component={Project}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/echipa/`}
              component={Team}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/preturi/`}
              component={Pricing}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/intrebari-si-raspunsuri/`}
              component={Faq}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/proiect-bcvolumetrisch/`}
              component={BCVolumetrisch}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/proiect-cabanarausor/`}
              component={CabanaRausor}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/proiect-ericcosolutions/`}
              component={EriccoSolutions}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/proiect-fsgstudio/`}
              component={FSGStudio}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/proiect-impreunapentruanais/`}
              component={Impreunapentruanais}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/proiect-mtagroup/`}
              component={MTAGroup}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/proiect-refri-solutions/`}
              component={RefrigerationPanelsNT}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/proiect-modirom/`}
              component={Modirom}
            />

<Route
              exact
              path={`${process.env.PUBLIC_URL}/proiect-klinker-vest/`}
              component={KlinkerVest}
            />

<Route
              exact
              path={`${process.env.PUBLIC_URL}/proiect-thesquatbabe/`}
              component={TheSquatBabe}
            />

<Route
              exact
              path={`${process.env.PUBLIC_URL}/proiect-tsalaw/`}
              component={TSALaw}
            />

<Route
              exact
              path={`${process.env.PUBLIC_URL}/proiect-cfrtimisoara/`}
              component={CFRTimisoara}
            />

<Route
              exact
              path={`${process.env.PUBLIC_URL}/proiect-cscsacalaz/`}
              component={CSCSacalaz}
            />




            <Route
              exact
              path={`${process.env.PUBLIC_URL}/serviciu-dezvoltare-web/`}
              component={DezvoltareWeb}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/serviciu-identitate-vizuala/`}
              component={IdentitateVizuala}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/serviciu-marketing-digital-seo/`}
              component={MarketingDigitalSEO}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/serviciu-mentenanta-it/`}
              component={MentenantaIT}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/serviciu-servicii-foto-video/`}
              component={ServiciiFotoVideo}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/serviciu-social-media/`}
              component={SocialMedia}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/blog-digitalizare/`}
              component={Digitalizare}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/blog-branding-romania/`}
              component={BrandinginRomania}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/blog-crearea-identitatii-vizuale/`}
              component={Creareaidentitatiivizuale}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/blog-digitalizare-in-educatie/`}
              component={Digitalizareineducatie}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/blog-digitalizare-in-institutii-publice/`}
              component={Digitalizareinstitutiipublice}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/blog-etapele-digitalizarii/`}
              component={Etapeledigitalizarii}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/blog-modalitati-de-afirmare-in-online/`}
              component={Modalitatideafirmare}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/blog-respectarea-legislatiei/`}
              component={Respectarealegislatiei}
            />


            {/* 
                    <Route
            exact
            path={`${process.env.PUBLIC_URL}/despre-noi/`}
            component={About}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/contact/`}
            component={Contact}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/error/`}
            component={ErrorPage}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog/`}
            component={BlogSidebar}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/servicii/`}
            component={Service}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/proiecte/`}
            component={Project}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/echipa/`}
            component={Team}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/preturi/`}
            component={Pricing}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/intrebari-si-raspunsuri/`}
            component={Faq}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/proiect-bcvolumetrisch/`}
            component={BCVolumetrisch}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/proiect-cabanarausor/`}
            component={CabanaRausor}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/proiect-ericcosolutions/`}
            component={EriccoSolutions}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/proiect-fsgstudio/`}
            component={FSGStudio}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/proiect-impreunapentruanais/`}
            component={Impreunapentruanais}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/proiect-mtagroup/`}
            component={MTAGroup}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/serviciu-dezvoltare-web/`}
            component={DezvoltareWeb}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/serviciu-identitate-vizuala/`}
            component={IdentitateVizuala}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/serviciu-marketing-digital-seo/`}
            component={MarketingDigitalSEO}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/serviciu-mentenanta-it/`}
            component={MentenantaIT}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/serviciu-servicii-foto-video/`}
            component={ServiciiFotoVideo}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/serviciu-social-media/`}
            component={SocialMedia}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog-digitalizare/`}
            component={Digitalizare}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog-branding-romania/`}
            component={BrandinginRomania}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog-crearea-identitatii-vizuale/`}
            component={Creareaidentitatiivizuale}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog-digitalizare-in-educatie/`}
            component={Digitalizareineducatie}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog-digitalizare-in-institutii-publice/`}
            component={Digitalizareinstitutiipublice}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog-etapele-digitalizarii/`}
            component={Etapeledigitalizarii}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog-modalitati-de-afirmare-in-online/`}
            component={Modalitatideafirmare}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog-respectarea-legislatiei/`}
            component={Respectarealegislatiei}
          /> */}
          </Layout>
        </Switch>
      </BrowserRouter>
    </CookiesProvider>
  </>
}

export default Root;

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider defaultSetCookies={{ path: '/' }}>
      <Root />
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
