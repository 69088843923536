import React from 'react'
import AnimateLine from "../../../common/AnimateLine";
import Breadcrumb from "../../../common/Breadcrumb";
import SubscribArea from "../../../common/SubscribArea";
import ServiceDetailsWrap5 from '../ServiciiFotoVideo/ServiceDetailsWrap5';
import SEO from '@americanexpress/react-seo';


function ServiceDetails5() {
  return (
    <>
      <SEO
        title="e-Brandy - Digitalizare și branding afacere | Agenție Promovare Online"
        description="Agentie Web Design Romania, Web Design Timisoara, Promovare online 100%"
        keywords={['agentie', 'web design']}
        siteUrl="https://e-brandy.ro"
        image={{
          src: 'https://e-brandy.ro/img/logo.png'
        }}
      />
      <div className="ebrandy-wrap">
        <AnimateLine />
        <Breadcrumb pageName="Servicii Foto-Video" pageTitle="Servicii Foto-Video" />
        <ServiceDetailsWrap5 />
        <SubscribArea />
      </div>
    </>
  )
}

export default ServiceDetails5