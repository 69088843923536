import React from 'react'
import Breadcrumb from "../../../common/Breadcrumb";
import SubscribArea from "../../../common/SubscribArea";
import BlogDetailsWrap from "../BrandinginRomania/BlogDetailsWrap";
import SEO from '@americanexpress/react-seo';



function BlogDetails() {
  return (
    <>
      <SEO
        title="Branding în România. Blog agentie web design Timișoara"
        description="Articol de blog despre branding în România, solutii pentru brandingul României."
        keywords={['branding romania ', 'blog branding', 'reclame romanesti']}
        siteUrl="https://e-brandy.ro"
        image={{
          src: 'https://e-brandy.ro/img/blog/blog-branding-romania.png'
        }}
      />
      <Breadcrumb pageName="Branding în România " pageTitle="Branding în România" />
      <BlogDetailsWrap />
      <SubscribArea />

    </>
  )
}

export default BlogDetails