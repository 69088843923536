import React from "react";
import ServiceCardWrap from "./ServiceCardWrap";
import LatesPost from './LatesPost'



function ServiceWrap() {
  return (
    <>
      <ServiceCardWrap />
      <LatesPost />

    </>
  );
}

export default ServiceWrap;
