import React from 'react'
import { firestoreDB } from '../../firebase.config'
import { doc, getDoc, getDocs, collection } from 'firebase/firestore'

export default function Admin() {
    const [messages, setMessages] = React.useState([])
    const [subscribers, setSubscribers] = React.useState([])


    React.useEffect(() => {
        const getMessages = async () => {
            const querySnapshot = await getDocs(collection(firestoreDB, 'contactForm'))
            const messages = []
            querySnapshot.forEach((doc) => {
                messages.push({ id: doc.id, ...doc.data() })
            })
            setMessages(messages)
        }
        getMessages()
    }, [])

    React.useEffect(() => {
        const getSubscribers = async () => {
            const querySnapshot = await getDocs(collection(firestoreDB, 'newsletterSubscribers'))
            const subscribers = []
            querySnapshot.forEach((doc) => {
                subscribers.push({ id: doc.id, ...doc.data() })
            })
            setSubscribers(subscribers)
        }
        getSubscribers()
    }, [])


    return (
        <div>
            <h1>Admin</h1>
            <h2>Mesaje</h2>
            <table>
                <thead>
                    <tr>
                        <th>Nume</th>
                        <th>Email</th>
                        <th>Subiect</th>
                        <th>Mesaj</th>
                        <th>Data</th>
                    </tr>
                </thead>
                <tbody>
                    {messages.map((message) => (
                        <tr key={message.id}>
                            <td>{message.name}</td>
                            <td>{message.email}</td>
                            <td>{message.subject}</td>
                            <td>{message.message}</td>
                            <td>{message.createdAt.toDate().toLocaleDateString()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <h2>Abonați</h2>
            <table>
                <thead>
                    <tr>
                        <th>Email</th>
                        <th>Data</th>
                    </tr>
                </thead>
                <tbody>
                    {subscribers.map((subscriber) => (
                        <tr key={subscriber.id}>
                            <td>{subscriber.email}</td>
                            <td>{subscriber.createdAt.toDate().toLocaleDateString()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

        </div>
    )
}