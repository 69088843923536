import React from 'react'
import { Link } from 'react-router-dom'
import "swiper/css/autoplay";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper";
SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);

function ProjectDetailsContent6() {
  const projectSlider = {
    loop: true,
    slidesPerView: 1,
    navigation: {
      nextEl: ".swiper-button-next-c",
      prevEl: ".swiper-button-prev-c",
    }
  }
  return (
    <>
      <div className="project-details-content">
        <div className="project-thumb">
          <img decoding="async" src={process.env.PUBLIC_URL + '/img/project/mtagroup.jpg'} alt="MTA Group logo, link" loading="lazy" title="MTA Group" />
          <div className="tag">
            <a href="https://mta-group.eu">Website MTA Group</a>
          </div>
        </div>
        <h3>MTA Group</h3>
        <p>Colaborarea cu Alessandro a început prin crearea site-ului pentru compania sa, MTA Group. Primii pași au fost câteva discuții despre tipul de site dorit și audiența care trebuie atinsă. Discuțiile au dus la formarea și implementarea unui plan media pe termen lung, ce include atât conținutul websiteului, cât și conținut pentru social media sau pentru zona de offline (flyere, invitații, carte vizită etc). </p>
        <div className="clinet-need">
          <h4>Nevoia clientului:</h4>
          <p>Cerințele lui Alessandro au fost mereu clare, culorile folosite sunt negru și auriu, iar design-ul trebuie să fie atractiv. Pasiunea pentru fotbal este una comună, motiv pentru care a fost foarte ușor să ajungem la un numitor comun în privința procesului de lucru. </p>
          <p>Am continuat cu un brainstorming despre ce ar trebui implementat pentru a obține sustenabilitate și vizibilitate pe termen lung. În acest sens, am conturat un plan ce include atât promovare online cât și offline. Pe parcurs, am realizat materiale de promovare în format video, postări atractive pentru anunțurile importante și am păstrat mereu siteul la zi cu informația.
            În prezent, continuăm să dezvoltăm proiectul și ne bucurăm de încrederea lui Alessandro, căruia îi mulțumim pentru un parteneriat solid de ambele părți. </p>
        </div>
      </div>

    </>
  )
}

export default ProjectDetailsContent6