import React from "react";
import { Link } from "react-router-dom";

function Footer(props) {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" })
  return (
    <>
      <footer className={props.footerAddclass} >
        <div className="container">
          <div className="footer-top">
            <div className="row">
              <div className="col-md-3 col-lg-3 col-xl-3">
                <div className="footer-widget">
                  <div className="footer-logo">
                    <img decoding="async" src={process.env.PUBLIC_URL + "/img/logo.png"} loading="lazy" alt="Logo e-Brandy - Agentie web design Romania" title="Logo e-Brandy" class="logo-ebrandy" />
                    <a href="https://anpc.ro/ce-este-sal/">
                      <img decoding="async"
                        src={
                          process.env.PUBLIC_URL + "/img/SAL.png"
                        }
                        loading="lazy" alt="Soluționarea alternativă a litigiilor. Legislație SOL" title="Buton ANPC SAL" class="sal"
                      /></a>
                    <Link to={`${process.env.PUBLIC_URL}`} onClick={scrollTop}>
                      <a href="https://anpc.ro">
                        <img decoding="async"
                          src={
                            process.env.PUBLIC_URL + "/img/SOL.png"
                          }
                          loading="lazy" alt="Soluționarea alternativă a litigiilor. Legislație SOL" title="Buton ANPC SOL" class="sol"
                        /></a></Link>
                  </div>
                  <ul className="social-media-icons text-center">
                    <li class="fb-icon">
                      <a href="https://facebook.com/Ebrandystudios">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/ebrandystudios/">
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.pinterest.com/ebrandystudios/">
                        <i className="fab fa-pinterest-p" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/ebrandystudios/">
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                  </ul>



                </div>
              </div>
              <div className="col-md-3 col-lg-3 col-xl-3">
                <div className="footer-widget">
                  <h4>Serviciile Noastre</h4>
                  <ul className="footer-menu">
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/serviciu-dezvoltare-web`} onClick={scrollTop}>Dezvoltare Website</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/serviciu-social-media`} onClick={scrollTop}>Social Media</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/serviciu-marketing-digital-seo`} onClick={scrollTop}>Marketing digital - SEO</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/serviciu-servicii-foto-video`} onClick={scrollTop}>Servicii Foto-Video</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/serviciu-identitate-vizuala`} onClick={scrollTop}>Logo si identitate vizuală</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/serviciu-mentenanta-it`} onClick={scrollTop}>Mentenanță IT</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-lg-3 col-xl-3">
                <div className="footer-widget">
                  <h4>Link-uri Utile</h4>
                  <ul className="footer-menu">
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/despre-noi`} onClick={scrollTop}>Despre Noi</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/servicii`} onClick={scrollTop}>Servicii</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/proiecte`} onClick={scrollTop}>Proiecte</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/blog`} onClick={scrollTop}>Blog</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/contact`} onClick={scrollTop}>Contact</Link>
                    </li>
                    <li>

                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-lg-3 col-xl-3">
                <div className="footer-widget">
                  <h4>Contact</h4>
                  <div className="number">
                    <div className="num-icon">
                      <i className="fas fa-phone-alt" />
                    </div>
                    <div className="phone">
                      <a href="tel:05661111985">+4 0721 126 226</a>
                      <a href="tel:06571111576">+4 0727 652 815</a>
                    </div>
                  </div>
                  <div className="office-mail">
                    <div className="mail-icon">
                      <i className="far fa-envelope" />
                    </div>
                    <div className="email">
                      <a href="mailto:contact@ebrandy.ro">contact@ebrandy.ro</a>
                      <a href="mailto:tehnic@ebrandy.ro">tehnic@ebrandy.ro</a>
                    </div>
                  </div>
                  <div className="address">
                    <div className="address-icon">
                      <i className="fas fa-map-marker-alt" />
                    </div>
                    <p>Bvd. Sudului, nr. 53A, clădirea A, ap. 31, Giroc, Timiș</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row align-items-center">
              <div className="col-12 col-md-4 col-lg-4 col-xl-5">
                <div className="copy-txt">
                  <span>
                    Copyright 2024 <b>e-Brandy</b> | Toate drepturile rezervate.

                  </span>
                </div>
              </div>
              <div className="col-12 col-md-8 col-lg-8 col-xl-7">
                <ul className="footer-bottom-menu">
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/gdpr/termeni-si-conditii.pdf`} target='_blank'>Termeni și condiții</Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/gdpr/politica-de-confidentialitate.pdf`} target='_blank'>Politică de confidențialitate</Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/gdpr/cookies.pdf`} target='_blank' >Politică cookies</Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/gdpr/GDPR.pdf`} target='_blank'>GDPR</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer >
    </>
  );
}

export default Footer;
