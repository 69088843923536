import React from 'react'
import { Link } from 'react-router-dom'
import "swiper/css/autoplay";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper";
SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);

function ProjectDetailsContent5() {
  const projectSlider = {
    loop: true,
    slidesPerView: 1,
    navigation: {
      nextEl: ".swiper-button-next-c",
      prevEl: ".swiper-button-prev-c",
    }
  }
  return (
    <>
      <div className="project-details-content">
        <div className="project-thumb">
          <img decoding="async" src={process.env.PUBLIC_URL + '/img/project/impreunapentruanais.jpg'} alt="Impreuna pentru Anais logo, link" loading="lazy" title="Impreuna pentru Anais" />
          <div className="tag">
            <a href="https://impreunapentruanais.ro">Website Impreuna pentru Anais</a>
          </div>
        </div>
        <h3>Împreună pentru Anais</h3>
        <p>Povestea completă despre motivul colaborării poate fi citită pe <a href="https://impreunapentruanais.ro/"> împreunăpentruanais.ro. </a> Proiectul este unul caritabil, ce are ca scop strângerea de fonduri pentru Anais, diagnosticată cu epilepsie.</p>
        <div className="clinet-need">
          <h4>Colaborarea alături de ONG</h4>
          <p>Povestea completă despre motivul colaborării poate fi citită pe împreunăpentruanais.ro.  Proiectul este unul caritabil, ce are ca scop strângerea de fonduri pentru Anais, diagnosticată cu epilepsie.

            În acest sens, am ajutat cu crearea websiteului și crearea de platforme de Social Media (facebook și Instagram) pentru domnul Marcel Ienci. Am colaborat în strânsă legătură cu dânsul, pentru a pune pe website fiecare detaliu și pentru a susține într-un mod cât mai util cauza micuței.
          </p>
          <p>De asemenea, am făcut posibilă plata direct prin intermediul websiteului, având ca partener platforma euplătesc.ro. Mai apoi, am spus povestea prin Social Media, ajungând într-o manieră organică la peste 40.000 de oameni.

            Suntem în continuare aproape de Anais, continuând să facem toate demeresurile pentru a asigura tratementul necesar. </p>
        </div>
      </div>

    </>
  )
}

export default ProjectDetailsContent5