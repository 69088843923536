import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function BlogDetailsContent3() {
  const [headings, setHeadings] = useState([]);
  const findHeadings = () => {
    const contentHeadings = document.querySelectorAll('.blog-details-content h4');
    const headingsArray = Array.from(contentHeadings).map((heading) => ({
      id: heading.id,
      text: heading.textContent,
    }));
    setHeadings(headingsArray);
  };

  useEffect(() => {
    findHeadings();
  }, []);

  const shareOnFacebook = () => {
    window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(window.location.href), '_blank');
  };

  const shareOnTwitter = () => {
    window.open('https://twitter.com/intent/tweet?url=' + encodeURIComponent(window.location.href), '_blank');
  };

  const shareOnPinterest = () => {
    window.open('https://www.pinterest.com/pin/create/button/?url=' + encodeURIComponent(window.location.href), '_blank');
  };

  const shareOnInstagram = () => {
    if (navigator.share) {
      navigator
        .share({
          url: window.location.href,
        })
        .then(() => console.log('Shared successfully'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
      console.log('Instagram sharing not supported');
    }
  };

  const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

  return (
    <>
      <div className="blog-details-content">
        <center><h2>Folosirea tehnolgiei în procesele educative</h2></center>
        <p>Digitalizarea a devenit o componentă tot mai importantă în toate aspectele vieții noastre, inclusiv în domeniul educației. În ultimii ani, sistemul educațional din România a început să se adapteze la provocările și oportunitățile pe care le oferă tehnologia digitală. Digitalizarea în învățământul românesc aduce cu sine atât avantaje, cât și provocări ce trebuie abordate cu atenție. </p>
        <div className="details-thumb">
          <img decoding="async" src={process.env.PUBLIC_URL + "/img/blog/blog-digitalizare-in-educatie.png"} alt="Tableta care are browserul web deschis" loading="lazy" title="Digitalizarea in educatie" />
        </div>
        <nav>
          <h3>Cuprins</h3>
          {headings.map((heading, index) => (
            <li key={heading.id}>
              <a href={`#${heading.id}`}>{`${(index + 1).toString().padStart(2, '0')}. ${heading.text}`}</a>
            </li>
          ))}
        </nav>
        <div class="divider"></div>
        <div className="blog-quote">
          <i className="fas fa-quote-left" />
          <p>"Digitalizarea nu este doar viitorul, ci și prezentul în care trăim." <br></br> - Satya Nadella, CEO <a href="https://www.microsoft.com/ro-ro/">Microsoft</a></p>
        </div>
        <h4 id="Resurse nelimitate pentru educație datorită digitalizării?">Resurse nelimitate pentru educație datorită digitalizării?</h4>
        <div className="special">
          <p>Unul dintre avantajele majore ale digitalizării în sistemul educațional este accesibilitatea. Tehnologiile digitale permit elevilor și profesorilor să aibă acces la o gamă largă de resurse și materiale educaționale online, indiferent de locație sau oră. De asemenea, digitalizarea facilitează comunicarea între elevi și profesori, prin intermediul platformelor de e-learning sau a aplicațiilor de mesagerie.</p>
        </div>

        <div className="space"></div>
        <h4 id="Personalizarea învățării">Personalizarea învățării</h4>
        <p>Un alt beneficiu al digitalizării în educație este personalizarea învățării. Prin intermediul tehnologiilor digitale, profesorii pot crea materiale și activități adaptate la nevoile și ritmul de învățare al fiecărui elev. Astfel, procesul de învățare devine mai eficient și mai captivant, stimulând interesul și motivația elevilor.</p>
        <div className="space"></div>
        <center><img decoding="async" src={process.env.PUBLIC_URL + "/img/blog/personalizarea-invatarii.png"} alt="Elevi in sala folosesc calculatoare pentru a invata" loading="lazy" title="" copyright="https://freepik.com" /></center>
        <div className="space"></div>

        <h4 id="Accesibilitate la tehnologie">Accesibilitate la tehnologie</h4>
        <p>Cu toate acestea, digitalizarea în sistemul educațional românesc nu vine fără provocări. Una dintre principalele provocări este accesibilitatea la tehnologie. Nu toți elevii din România au acces la echipamente și conexiuni stabile la internet. Aceasta poate crea inegalități în procesul de învățare și poate amplifica decalajele existente între elevi.</p>

        <div className="space"></div>
        <h4 id="Utilizarea tehnologiei digitale în sala de clasă">Utilizarea tehnologiei digitale în sala de clasă</h4>
        <p>Încă un aspect important este pregătirea cadrelor didactice pentru utilizarea tehnologiilor digitale în clasă. Profesorii trebuie să fie pregătiți și susținuți în adaptarea la noile metode și instrumente digitale. Formarea continuă a profesorilor și investițiile în infrastructura digitală sunt necesare pentru ca digitalizarea să fie implementată cu succes în învățământul românesc.</p>
        <center><img decoding="async" src={process.env.PUBLIC_URL + "/img/blog/tehnologie-la-scoala.png"} alt="Patru persoane folosesc calculatoare pentru a invata web design" loading="lazy" title="Folosirea tehnologiei web" /></center>

        <div class="divider"></div>
        <p>În concluzie, digitalizarea în sistemul educațional din România aduce o serie de oportunități și beneficii în procesul de învățare. Accesibilitatea la resurse educaționale online și personalizarea învățării sunt doar câteva dintre avantajele oferite de tehnologiile digitale. Cu toate acestea, este important să abordăm și provocările legate de inegalitatea în accesul la tehnologie și pregătirea profesorilor. Prin investiții adecvate și susținere continuă, digitalizarea poate contribui la îmbunătățirea sistemului educațional din România și la pregătirea elevilor.</p>
        <div className="tag-share">
          <div className="line-tag">
            <span>Tag:</span>
            <Link to={`${process.env.PUBLIC_URL}/serviciu-dezvoltare-web`} onClick={scrollTop}>web,</Link>
            <Link to={`${process.env.PUBLIC_URL}/serviciu-identitate-vizuala`} onClick={scrollTop}>branding,</Link>
            <Link to={`${process.env.PUBLIC_URL}/serviciu-social-media`} onClick={scrollTop}>social media,</Link>
          </div>
          <div className="share-blog">
            <span>Share On:</span>
            <ul className="social-share-blog">
              <li>
                <a href="#" onClick={shareOnFacebook}>
                  <i className="fab fa-facebook-f" />
                </a>
              </li>
              <li>
                <a href="#" onClick={shareOnTwitter}>
                  <i className="fab fa-twitter" />
                </a>
              </li>
              <li>
                <a href="#" onClick={shareOnPinterest}>
                  <i className="fab fa-pinterest-p" />
                </a>
              </li>
              <li>
                <a href="#" onClick={shareOnInstagram}>
                  <i className="fab fa-instagram" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div >
    </>
  );
}

export default BlogDetailsContent3;