import React from "react";
import { Link } from "react-router-dom";
import ServiceDetailsItems2 from "./ServiceDetailsItems2";

function ServiceDetailsWrap2() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" })
  return (
    <>
      <div className="service-details sec-mar-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="service-details-content">
                <img decoding="async" src={process.env.PUBLIC_URL + '/img/blog/logo-identitate-vizuala-thumnail.jpg'} alt="Doua persoane lucreaza pentru stabilirea identitatii vizuale, materiale grafice" loading="lazy" title="Logo si identitate vizuala afacere" />
                <h3>
                  <i>
                    <img decoding="async"
                      src={process.env.PUBLIC_URL + '/img/icons/service-icon-5.png'}
                      alt="Logo si identitate vizuala, icon" loading="lazy"
                    />
                  </i>
                  Logo și identitate vizuală
                </h3>
                <p>
                  Logo-ul și identitatea vizuală sunt aspecte fundamentale pentru o afacere de succes. Ele reprezintă identitatea și personalitatea brandului, oferind o imagine distinctă și memorabilă în mintea clienților. Prin intermediul unui logo bine proiectat și unei identități vizuale coerente, o companie poate transmite încredere, profesionalism și valori fundamentale, captând atenția și fidelizând publicul țintă.
                </p>
                <p>
                  Importanța logo-ului și a identității vizuale constă în crearea unei amprente vizuale distinctive pe piață. Prin utilizarea culorilor, formelor, tipografiei și altor elemente grafice specifice, se creează o coerență vizuală între toate materialele de marketing și comunicare. Astfel, logo-ul și identitatea vizuală ajută la crearea unei imagini consistente și memorabile, facilitând recunoașterea brandului și consolidând încrederea în rândul clienților.
                </p>
                <p>
                  O identitate vizuală bine dezvoltată și un logo puternic transmit profesionalism și stabilitate în ochii clienților. Ele reflectă valorile și misiunea unei companii și servesc ca instrumente de comunicare eficiente. Prin intermediul logo-ului și identității vizuale, o afacere poate diferenția de concurență, construi o imagine puternică și atrage atenția potențialilor clienți. Aceste elemente reprezintă investiții esențiale în succesul și dezvoltarea unei afaceri pe termen lung.{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar-widget">
                <h4>Suntem alături de tine în următoarele:</h4>
                <ul className="category">
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/serviciu-dezvoltare-web`} onClick={scrollTop}>
                      Dezvoltare web
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/serviciu-social-media`} onClick={scrollTop}>
                      Social Media
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/serviciu-mentenanta-it`} onClick={scrollTop}>
                      Mentenanță IT
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/serviciu-servicii-foto-video`} onClick={scrollTop}>
                      Servicii Foto-Video
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/serviciu-identitate-vizuala`} onClick={scrollTop}>
                      Logo și identitate vizuală
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/serviciu-marketing-digital-seo`} onClick={scrollTop}>
                      Marketing Digital-SEO
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="sidebar-banner">
                <img src={process.env.PUBLIC_URL + "/img/widget-banner-bg.jpg"} alt="Detalii contact pentru servicii digitalizare si branding" />
                <div className="banner-inner">
                  <h3>
                    <a href="tel:+40721126226">
                      Prețuri începând <span>de la</span><span> +4 0721 126 226</span><img className="angle" src={process.env.PUBLIC_URL + "/img/arrow-angle.png"} alt="Directie sageata catre detalii de contact pentru servicii de branding si digitalizare" />
                      <span> 50euro</span> </a>
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <ServiceDetailsItems2 />
        </div>
      </div>
    </>
  );
}

export default ServiceDetailsWrap2;
