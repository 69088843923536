import React from 'react'
import ProjectDetailsContent1 from '../BCVolumetrisch/ProjectDetailsContent1'
import RelatedProject1 from '../BCVolumetrisch/RelatedProject1'
import SidebarWidget1 from '../BCVolumetrisch/SidebarWidget1'

function ProjectDetailsWrap1() {
  return (
    <div>
      <div className="project-details sec-mar-top">

        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <ProjectDetailsContent1 />
            </div>
            <div className="col-lg-4">
              <SidebarWidget1 />
            </div>
            <RelatedProject1 />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectDetailsWrap1