import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function BlogDetailsContent4() {
  const [headings, setHeadings] = useState([]);
  const findHeadings = () => {
    const contentHeadings = document.querySelectorAll('.blog-details-content h4');
    const headingsArray = Array.from(contentHeadings).map((heading) => ({
      id: heading.id,
      text: heading.textContent,
    }));
    setHeadings(headingsArray);
  };

  useEffect(() => {
    findHeadings();
  }, []);

  const shareOnFacebook = () => {
    window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(window.location.href), '_blank');
  };

  const shareOnTwitter = () => {
    window.open('https://twitter.com/intent/tweet?url=' + encodeURIComponent(window.location.href), '_blank');
  };

  const shareOnPinterest = () => {
    window.open('https://www.pinterest.com/pin/create/button/?url=' + encodeURIComponent(window.location.href), '_blank');
  };

  const shareOnInstagram = () => {
    if (navigator.share) {
      navigator
        .share({
          url: window.location.href,
        })
        .then(() => console.log('Shared successfully'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
      console.log('Instagram sharing not supported');
    }
  };

  const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

  return (
    <>
      <div className="blog-details-content">
        <center><h2>Digitalizarea în instituțiile publice din România</h2></center>
        <p>Digitalizarea este un proces esențial pentru instituțiile publice din România în anul 2023. Aceasta reprezintă tranziția către utilizarea tehnologiei și a mediului online pentru a oferi servicii mai eficiente și mai accesibile cetățenilor. Importanța digitalizării în instituțiile publice se reflectă într-o serie de beneficii cheie pe care le vom enumera mai jos.</p>
        <div className="details-thumb">
          <img decoding="async" src={process.env.PUBLIC_URL + "/img/blog/blog-digitalizarea-in-institutii-publice.png"} alt="Persoana analizeaza informatii despre digitalizare" loading="lazy" title="Digitalizarea in institutii publice" />
        </div>
        <nav>
          <h3>Cuprins</h3>
          {headings.map((heading, index) => (
            <li key={heading.id}>
              <a href={`#${heading.id}`}>{`${(index + 1).toString().padStart(2, '0')}. ${heading.text}`}</a>
            </li>
          ))}
        </nav>
        <div class="divider"></div>
        <div className="blog-quote">
          <i className="fas fa-quote-left" />
          <p>Transformarea digitală a instituțiilor publice este esențială pentru a construi un guvern modern și eficient, care să își servească eficient cetățenii. <br></br> - Angela Merkel, Fosta Cancelar federal al Germaniei
          </p>
        </div>
        <h4 id="Eficienta">Eficienta</h4>
        <div className="special">
          <p>Prin digitalizare, instituțiile publice pot optimiza fluxul de lucru și pot simplifica procedurile administrative. Astfel, activitățile care în trecut necesitau mult timp și resurse pot fi realizate mai rapid și la costuri mai mici. Cetățenii vor beneficia de procese mai rapide și vor economisi timp prețios.</p>
        </div>
        <h4 id="Transparență">Transparență</h4>
        <div className="special">
          <p>Digitalizarea facilitează accesul la informații pentru cetățeni. Prin intermediul platformelor online, aceștia pot accesa rapid și ușor informațiile publice relevante, precum legi, reglementări sau bugete. Astfel, se promovează transparența și responsabilitatea în administrația publică.</p>
        </div>
        <h4 id="Accesibilitate">Accesibilitate</h4>
        <div className="special">
          <p>Mediul digital deschide noi oportunități pentru cetățeni de a accesa serviciile publice. Prin intermediul platformelor online, aceștia pot solicita acte de identitate, certificări sau alte documente necesare, fără a fi nevoie să se deplaseze fizic la biroul instituției. Acest aspect este deosebit de important în situații de urgență, epidemii sau la nivel regional, unde deplasarea poate fi dificilă sau costisitoare.</p>
        </div>
        <div class="space"></div>
        <center><img decoding="async" src={process.env.PUBLIC_URL + "/img/blog/digitalizarea-institutii-publice-1.png"} alt="Incapere mare cu functionario publici in tipul unei sedinte" loading="lazy" title="Acessibilitate web design" /></center>
        <div class="space"></div>
        <h4 id="Economie">Economie</h4>
        <div className="special">
          <p>Digitalizarea poate aduce economii semnificative în ceea ce privește costurile administrative și resursele necesare. Prin reducerea hârtiei utilizate, depozitarea datelor online și simplificarea procedurilor, instituțiile publice pot reduce cheltuielile și pot optimiza resursele. Astfel, banii economisiți pot fi redirecționați către alte proiecte sau servicii publice.</p>
        </div>
        <div class="space"></div>
        <h4 id="Participare">Participare</h4>
        <div className="special">
          <p>Digitalizarea poate facilita participarea cetățenilor în procesul decizional. Prin intermediul platformelor online, cetățenii pot fi implicați în consultări publice, sondaje de opinie sau pot avea acces la informații despre proiectele de interes public. Astfel, se promovează implicarea activă a cetățenilor în luarea deciziilor și se consolidează democrația participativă.</p>
        </div>
        <div class="space"></div>
        <center><img decoding="async" src={process.env.PUBLIC_URL + "/img/blog/digitalizarea-institutii-publice-2.png"} alt="Cinci functionari publici in era digitala" loading="lazy" title="Web design institutii publice Timisoara" /></center>
        <div class="space"></div>
        <div className='divider'></div>
        <p>În concluzie, digitalizarea în instituțiile publice din România în 2023 este esențială pentru a aduce o serie de beneficii atât cetățenilor, cât și administrației publice. Eficiența crescută, transparența sporită, accesibilitatea îmbunătățită și economiile aduse reprezintă câteva dintre motivele principale pentru care digitalizarea este o prioritate în acest moment. Prin investiții în tehnologie și formarea resurselor umane specializate, România poate continua să avanseze în direcția unei societăți digitale și moderne.</p>
        <div className="tag-share">
          <div className="line-tag">
            <span>Tag:</span>
            <Link to={`${process.env.PUBLIC_URL}/serviciu-dezvoltare-web`} onClick={scrollTop}>web,</Link>
            <Link to={`${process.env.PUBLIC_URL}/serviciu-identitate-vizuala`} onClick={scrollTop}>branding,</Link>
            <Link to={`${process.env.PUBLIC_URL}/serviciu-social-media`} onClick={scrollTop}>social media,</Link>
          </div>
          <div className="share-blog">
            <span>Share On:</span>
            <ul className="social-share-blog">
              <li>
                <a href="#" onClick={shareOnFacebook}>
                  <i className="fab fa-facebook-f" />
                </a>
              </li>
              <li>
                <a href="#" onClick={shareOnTwitter}>
                  <i className="fab fa-twitter" />
                </a>
              </li>
              <li>
                <a href="#" onClick={shareOnPinterest}>
                  <i className="fab fa-pinterest-p" />
                </a>
              </li>
              <li>
                <a href="#" onClick={shareOnInstagram}>
                  <i className="fab fa-instagram" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div >
    </>
  );
}

export default BlogDetailsContent4;