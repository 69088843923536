import React from "react";
import { Link } from "react-router-dom";
import ServiceDetailsItems5 from "./ServiceDetailsItems5";

function ServiceDetailsWrap5() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" })
  return (
    <>
      <div className="service-details sec-mar-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="service-details-content">
                <img src={process.env.PUBLIC_URL + '/img/blog/servicii-foto-video-thumnail.jpg'} alt="Obiecte diferite pentru servicii foto video, camera foto, obiectiv, casti" loading="lazy" title="Serviciu foto-video" />
                <h3>
                  <i>
                    <img
                      src={process.env.PUBLIC_URL + '/img/icons/service-icon-4.png'}
                      alt="service icon images"
                    />
                  </i>
                  Servicii Foto-Video
                </h3>
                <p>
                  Serviciile de fotografiere și video sunt de o importanță crucială în realizarea unei afaceri de succes. Imaginile și videoclipurile de calitate profesională reprezintă un instrument puternic de comunicare și marketing. Acestea permit afacerii să își prezinte produsele, serviciile și brandul într-un mod atrăgător și captivant. Fotografiile și videoclipurile bine realizate pot crea o impresie pozitivă asupra potențialilor clienți și pot stimula interesul și angajamentul acestora față de afacerea respectivă.
                </p>
                <p>
                  Utilizarea serviciilor de fotografiere și video într-o afacere contribuie la creșterea vizibilității și notorietății acesteia. Imaginile de calitate pot fi utilizate în diverse canale de promovare, precum site-ul web, materialele de marketing, rețelele de socializare sau broșurile comerciale. Prin intermediul fotografiilor și videoclipurilor, afacerea poate transmite un mesaj clar și coerent, atrăgând atenția potențialilor clienți și diferențiindu-se de concurență.
                </p>
                <p>
                  Serviciile de fotografiere și video contribuie la construirea unei imagini profesionale și de încredere a afacerii. Imaginile de calitate și videoclipurile bine realizate transmit un sentiment de profesionalism și seriozitate, ceea ce poate consolida încrederea clienților în afacere. Acestea pot evidenția calitatea produselor sau serviciilor oferite și pot ilustra povestea și valorile brandului. Prin prezentarea unei imagini coerente și autentice, afacerea poate atrage și fideliza clienți, contribuind astfel la succesul pe termen lung.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar-widget">
                <h4>Suntem alături de tine în următoarele:</h4>
                <ul className="category">
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/serviciu-dezvoltare-web`} onClick={scrollTop}>
                      Dezvoltare web
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/serviciu-social-media`} onClick={scrollTop}>
                      Social Media
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/serviciu-mentenanta-it`} onClick={scrollTop}>
                      Mentenanță IT
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/serviciu-servicii-foto-video`} onClick={scrollTop}>
                      Servicii Foto-Video
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/serviciu-identitate-vizuala`} onClick={scrollTop}>
                      Logo și identitate vizuală
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/serviciu-marketing-digital-seo`} onClick={scrollTop}>
                      Marketing Digital-SEO
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="sidebar-banner">
                <img src={process.env.PUBLIC_URL + "/img/widget-banner-bg.jpg"} alt="Detalii contact pentru servicii digitalizare si branding" />
                <div className="banner-inner">
                  <h3>
                    <a href="tel:+40721126226">
                      Prețuri începând <span>de la</span><span> +4 0721 126 226</span><img className="angle" src={process.env.PUBLIC_URL + "/img/arrow-angle.png"} alt="Directie sageata catre detalii de contact pentru servicii de branding si digitalizare" />
                      <span> 50euro</span> </a>
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <ServiceDetailsItems5 />
        </div>
      </div>
    </>
  );
}

export default ServiceDetailsWrap5;
