import React from "react";

function BannerWidget() {
  return (
    <>
      <div className="sidebar-banner">
        <img decoding="async" src={process.env.PUBLIC_URL + "/img/widget-banner-bg.jpg"} alt loading="lazy" />
        <div className="banner-inner">
          <h3>
            Agentie web design<span>Romania</span>

            <img className="angle" src={process.env.PUBLIC_URL + "/img/arrow-angle.png"} />
            <a href="tel:+40721126226">+4 0721 126 226</a>
          </h3>

        </div>
      </div>
      <div className="sidebar-banner">
        <img src={process.env.PUBLIC_URL + "/img/widget-banner-bg.jpg"} />
        <div className="banner-inner">

          <h3>
            Prețuri începând <span>de la</span>  <img className="angle" src={process.env.PUBLIC_URL + "/img/arrow-angle.png"} />
            <span> 50euro</span>
          </h3>
        </div>
      </div>
    </>
  );
}

export default BannerWidget;
