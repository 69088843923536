import React from "react";
import AnimateLine from "../../../common/AnimateLine";
import Breadcrumb from "../../../common/Breadcrumb";
import SubscribArea from "../../../common/SubscribArea";
import ProjectDetailsWrap3 from "../EriccoSolutions/ProjectDetailsWrap3";

function ProjectDetails3() {
  return (
    <>
      <div className="ebrandy-wrap">
        <AnimateLine />
        <Breadcrumb pageName="Ericco Solutions" pageTitle="Ericco Solutions" />
        <ProjectDetailsWrap3 />
        <SubscribArea />
      </div>
    </>
  );
}

export default ProjectDetails3;
