import React from 'react'
import Breadcrumb from "../../../common/Breadcrumb";
import SubscribArea from "../../../common/SubscribArea";
import BlogDetailsWrap5 from "../Etapeledigitalizarii/BlogDetailsWrap5";
import SEO from '@americanexpress/react-seo';



function BlogDetails5() {
  return (
    <>
      <SEO
        title="e-Brandy - Digitalizare și branding afacere | Agenție Promovare Online"
        description="Agentie Web Design Romania, Web Design Timisoara, Promovare online 100%"
        keywords={['agentie', 'web design']}
        siteUrl="https://e-brandy.ro"
        image={{
          src: 'https://e-brandy.ro/img/logo.png'
        }}
      />
      <Breadcrumb pageName="Etapele digitalizarii" pageTitle="Etapele digitalizării unei afaceri" />
      <BlogDetailsWrap5 />
      <SubscribArea />

    </>
  )
}

export default BlogDetails5
