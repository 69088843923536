import React from 'react'
import "swiper/css/autoplay";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Autoplay,
  Navigation,
} from "swiper";
SwiperCore.use([Navigation, Autoplay]);
function PartnarArea() {
  const partnarSlider = {
    loop: true,
    slidesPerView: 5,
    spaceBetween: 20,
    speed: 1000,
    autoplay: {
      delay: 600,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: '.swiper-button-next-c',
      prevEl: '.swiper-button-prev-c',
    },
    breakpoints: {
      320: {
        slidesPerView: 2,
      },
      576: {
        slidesPerView: 3,
      },
      992: {
        slidesPerView: 4,
      },
      1200: {
        slidesPerView: 5,
      }
    }
  }
  return (
    <>
      <section className="our-partner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3">
              <div className="sec-title white layout2">
                <span>e-Brandy</span>
                <h2>Tehnologii folosite</h2>
                <div className="-partnerslider-navigator">
                  <div className="swiper-button-prev-c"><i className="bi bi-chevron-left" /></div>
                  <div className="swiper-button-next-c"><i className="bi bi-chevron-right" /></div>
                </div>
              </div>
            </div>
            <div className="col-lg-9">
              <Swiper {...partnarSlider} className="swiper partner-slider">
                <div className="swiper-wrapper">
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <a href="https://www.adobe.com/ro/"> <img decoding="async" src={process.env.PUBLIC_URL + "/img/partner/partner-1.png"} alt="Adobe Logo, link" loading="lazy" title="Adobe" /></a>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <a href="https://www.android.com/"><img decoding="async" src={process.env.PUBLIC_URL + "/img/partner/partner-2.png"} alt="Android Logo, link" loading="lazy" title="Android" /></a>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <a href="https://www.techtarget.com/searchenterpriseai/definition/AI-Artificial-Intelligence/"> <img decoding="async" src={process.env.PUBLIC_URL + "/img/partner/partner-3.png"} alt="Definitie Inteligenta artificiala" loading="lazy" title="Definitie inteligenta artificiala" /></a>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <a href="https://en.wikipedia.org/wiki/Bootstrap_(front-end_framework)/"> <img decoding="async" src={process.env.PUBLIC_URL + "/img/partner/partner-5.png"} alt="Ce inseamna Boostrap, link" loading="lazy" title="Boostrap" /></a>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <a href="https://www.apple.com/ios/"><img decoding="async" src={process.env.PUBLIC_URL + "/img/partner/partner-7.png"} alt="Apple iOs logo si website, link" loading="lazy" title="Apple iOs" /></a>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <a href="https://www.microsoft.com/en-us/windows?r=1/"><img decoding="async" src={process.env.PUBLIC_URL + "/img/partner/partner-8.png"} alt="Microsoft Windows Logo, link" loading="lazy" title="Microsoft Windows" /></a>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <a href="https://wordpress.com/"> <img decoding="async" src={process.env.PUBLIC_URL + "/img/partner/partner-9.png"} alt="Wordpress Logo, link" loading="lazy" title="Wordpress" /></a>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <a href="https://react.dev/"> <img decoding="async" src={process.env.PUBLIC_URL + "/img/partner/partner-10.png"} alt="React Logo, link" loading="lazy" title="React" /></a>
                    </div>
                  </SwiperSlide>

                </div>
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default PartnarArea