import React from "react";
import { useState, useEffect } from "react";
import Footer from "../common/Footer";
import Header from "../common/Header";
import HomePageOne from "../page/home/HomePageOne";

import { firebaseApp } from "../../firebase.config";
import { getAnalytics, logEvent } from "firebase/analytics";
import CookieConsent, { Cookies } from "react-cookie-consent";

function MainLayout() {
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    const analytics = getAnalytics(firebaseApp);
    //log event page_view
    logEvent(analytics, "page_view", {
      page_title: "Home Page",
      page_location: "http://localhost:3000/",
      page_path: "/",
    });
  }, []);


  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (

    <>
      <CookieConsent
        location="bottom"
        buttonText="Sunt de acord"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#326098", fontSize: "13px" }}
        expires={150}
      >
        Acest website utilizează cookie pentru îmbunătățirea experienței utilizatorului. Continuarea vizitei pe acest website, reprezintă acceptul tău.<br></br>{" "}
        <span style={{ fontSize: "14px" }}><a href="https://e-brandy.ro/gdpr/cookies.pdf">Politică cookies</a></span>
      </CookieConsent>
      <Header />
      <HomePageOne />
      <Footer />
    </>
  )
}



export default MainLayout;