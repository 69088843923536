import React from 'react'
import { Link } from 'react-router-dom'

function CatagoryWidget() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" })
  return (
    <>
      <div className="sidebar-widget">
        <h4>Află despre serviciile noastre</h4>
        <ul className="category">
          <li><Link to={`${process.env.PUBLIC_URL}/serviciu-dezvoltare-web`} onClick={scrollTop}>Dezvoltare web<i className="bi bi-arrow-right" /></Link></li>
          <li><Link to={`${process.env.PUBLIC_URL}/serviciu-social-media`} onClick={scrollTop}>Social Media<i className="bi bi-arrow-right" /></Link></li>
          <li><Link to={`${process.env.PUBLIC_URL}/serviciu-marketing-digital-seo`} onClick={scrollTop}>Marketing Digital și SEO <i className="bi bi-arrow-right" /></Link></li>
          <li><Link to={`${process.env.PUBLIC_URL}/serviciu-servicii-foto-video`} onClick={scrollTop}>Servicii Foto-Video<i className="bi bi-arrow-right" /></Link></li>
          <li><Link to={`${process.env.PUBLIC_URL}/serviciu-identitate-vizuala`} onClick={scrollTop}>Logo și identitate vizuală<i className="bi bi-arrow-right" /></Link></li>
          <li><Link to={`${process.env.PUBLIC_URL}/serviciu-mentenanta-it`} onClick={scrollTop}>Mentenanță IT<i className="bi bi-arrow-right" /></Link></li>
        </ul>
      </div>
    </>
  )
}

export default CatagoryWidget