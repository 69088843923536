import React from "react";
import { Link } from "react-router-dom";

function ServiceCard(props) {
  const { serviceNumber, layoutClass, image, title, details, url } = props;

  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className={`single-service ${layoutClass}`}>
      <span>{serviceNumber}</span>
      <div className="icon">
        <img decoding="async" src={`${process.env.PUBLIC_URL}${image}`} alt="Servicii web design si promovare online, butoane servicii" loading="lazy" title="Agentie web design Romania" />
      </div>
      <h4>{title}</h4>
      <p>{details}</p>
      <div className="read-btn">
        <Link to={`${process.env.PUBLIC_URL}/${url}`} onClick={handleLinkClick}>
          Află mai multe
        </Link>
      </div>
    </div>
  );
}

export default ServiceCard;