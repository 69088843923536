import React from "react";
import AnimateLine from "../../../common/AnimateLine";
import Breadcrumb from "../../../common/Breadcrumb";
import SubscribArea from "../../../common/SubscribArea";
import ProjectDetailsWrap6 from "../MTAGroup/ProjectDetailsWrap6";

function ProjectDetails6() {
  return (
    <>
      <div className="ebrandy-wrap">
        <AnimateLine />
        <Breadcrumb pageName="MTA Group" pageTitle="MTA Group" />
        <ProjectDetailsWrap6 />
        <SubscribArea />
      </div>
    </>
  );
}

export default ProjectDetails6;
