import React from 'react'
import { Link } from 'react-router-dom'
import "swiper/css/autoplay";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper";
SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);

function ProjectDetailsContent7() {
  const projectSlider = {
    loop: true,
    slidesPerView: 1,
    navigation: {
      nextEl: ".swiper-button-next-c",
      prevEl: ".swiper-button-prev-c",
    }
  }
  return (
    <>
      <div className="project-details-content">
        <div className="project-thumb">
          <img decoding="async" src={process.env.PUBLIC_URL + '/img/project/refrisolutions.png'} alt="Refrigeration Panels North Texas logo, link" loading="lazy" title="Refrigeration Panels North Texas" />
          <div className="tag">
            <a href="https://refri-solutions.com/">Website Refrigeration Panels North Texas</a>
          </div>
        </div>
        <h3>Refrigeration Panels North Texas</h3>
        <p>Colaborarea noastră cu Nelu Cristian, reprezentantul dinamic al Refrigeration Panels North Texas, a început cu dorința comună de a consolida prezența online a companiei sale și de a atrage mai multe oportunități de afaceri.
</p>
        <div className="clinet-need">
          <h4>Nevoia clientului:</h4>
          <p>Fiecare aspect al acestui proiect a fost gândit și realizat cu scopul de a oferi o experiență online de neuitat pentru vizitatorii site-ului Refrigeration Panels North Texas. De la designul site-ului până la strategia de promovare online, am pus accentul pe valorile și serviciile de încredere pe care compania le oferă în domeniul panourilor frigorifice.</p>
          <p>În doar 30 de zile, am livrat servicii rapide și eficiente, adaptate nevoilor specifice ale Refrigeration Panels North Texas, pentru a asigura lansarea promptă a website-ului și începerea campaniilor de promovare online.</p>

        </div>
      </div>

    </>
  )
}

export default ProjectDetailsContent7