import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function BlogDetailsContent() {
  const [headings, setHeadings] = useState([]);
  const findHeadings = () => {
    const contentHeadings = document.querySelectorAll('.blog-details-content h4');
    const headingsArray = Array.from(contentHeadings).map((heading) => ({
      id: heading.id,
      text: heading.textContent,
    }));
    setHeadings(headingsArray);
  };

  useEffect(() => {
    findHeadings();
  }, []);

  const shareOnFacebook = () => {
    window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(window.location.href), '_blank');
  };

  const shareOnTwitter = () => {
    window.open('https://twitter.com/intent/tweet?url=' + encodeURIComponent(window.location.href), '_blank');
  };

  const shareOnPinterest = () => {
    window.open('https://www.pinterest.com/pin/create/button/?url=' + encodeURIComponent(window.location.href), '_blank');
  };

  const shareOnInstagram = () => {
    if (navigator.share) {
      navigator
        .share({
          url: window.location.href,
        })
        .then(() => console.log('Shared successfully'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
      console.log('Instagram sharing not supported');
    }
  };

  const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

  return (
    <>
      <div className="blog-details-content">
        <center><h2>Folosirea brandingului în România</h2></center>
        <p>Recent, România a fost numită una dintre cele mai frumoase țări din Europa. Cu toate acestea, imaginea țării noastre în străinătate poate fi uneori afectată de stereotipurile și prejudecățile care persistă în rândul mulțimii. Din acest motiv, este important să luăm măsuri pentru a ne brandui România în mod corect și eficient. În acest articol, vom explora câteva căi prin care putem promova România ca o destinație turistică atrăgătoare și o țară prietenoasă și inovatoare. </p>
        <div className="details-thumb">
          <img src={process.env.PUBLIC_URL + "/img/blog/blog-branding-romania.png"} alt="Drapelul Romaniei in forma de cerc, culori in ordinea rosu galben albastru" />
        </div>
        <nav>
          <h3>Cuprins</h3>
          {headings.map((heading, index) => (
            <li key={heading.id}>
              <a href={`#${heading.id}`}>{`${(index + 1).toString().padStart(2, '0')}. ${heading.text}`}</a>
            </li>
          ))}
        </nav>
        <div className='divider'></div>
        <div className="blog-quote">
          <i className="fas fa-quote-left" />
          <p>Cultura românească este o comoară vie, în care se reflectă tradițiile, arta, literatura și valorile noastre naționale. <br></br>- Mihai Eminescu -  <a href="https://ro.wikipedia.org/wiki/Mihai_Eminescu">poet, prozator și jurnalist român</a></p>
        </div>
        <div className="space"></div>
        <h4 id="Promovarea destinațiilor turistice">Promovarea destinațiilor turistice</h4>
        <div className="special">
          <p>România are multe<a href="https://locuridinromania.ro"> obiective turistice dezirabile</a>, de la frumusețea naturală a Munților Carpați la orașul medieval Sighișoara. Pentru a ne promova România într-un mod eficient, trebuie să ne concentrăm pe promovarea acestor destinații turistice. Putem face acest lucru prin intermediul marketingului, prin intermediul social media, prin campanii publicitare în străinătate și prin colaborarea cu diferiti influenceri din mediul online.</p>
        </div>

        <div className="space"></div>

        <h4 id="Susținerea inovației">Susținerea inovației</h4>
        <p>Promovarea inovației poate ajuta, de asemenea, la consolidarea imaginii noastre de țară inovatoare și modernă. Guvernul poate sprijini inițiativele de cercetare și dezvoltare prin oferirea de finanțare și prin crearea unui climat prielnic pentru afaceri. De asemenea, învățământul superior poate fi îmbunătățit prin orientarea spre dezvoltarea de competențe tehnologice și de afaceri pentru a oferi cetățenilor oportunitatea de a-și construi cariere de succes și de a deveni antreprenori.</p>
        <center><img decoding="async" src={process.env.PUBLIC_URL + "/img/blog/blog-promovarea-culturala-br.png"} alt="Cultura moderna, intr-un peisaj de seara cu stele, diferite cladiri in stil baroc" loading="lazy" title="Cultura moderna in Romania" copyright="https://freepik.com/" /> </center>
        <div className="space"></div>

        <h4 id="Stabilirea culturală">Stabilirea culturală</h4>
        <div className="special">
          <p>România are o istorie și o cultură bogată, care merită să fie promovate în întreaga lume. Prin promovarea patrimoniului cultural, a artei și a tradițiilor României, putem atrage atenția asupra bogatelor noastre resurse culturale. Dezvoltarea părții culturale a României este un obiectiv important la nivel national. Fiecare județ, care frumusețile și obiectivele sale distincte. Împreună formează cultura românească. </p>
        </div>
        <center><img decoding="async" src={process.env.PUBLIC_URL + "/img/blog/blog-stabilirea-culturala-br.png"} alt="Cladiri in stil baroc imaginate de ai din Romania" loading="lazy" title="Cladiri baroc Romania" copyright="https://freepik.com/" /></center>
        <div className="space"></div>
        <h4 id="Protecția mediului înconjurător">Protecția mediului înconjurător</h4>
        <div className="special">
          <p>România este acasă pentru o varietate de animale și plante care trăiesc în așezări rurale și urbane. Ne putem angaja să protejăm mediul pentru a ne păstra ecosistemele pline de viață și frumusețea naturală. Prin promovarea responsabilității față de mediu, România ar putea atrage un tip nou de turist - și anume, cei care caută vacanțe etice și responsabile. </p>
        </div>
        <div className='divider'></div>
        <p>În concluzie, promovarea României ca o țară turistică atrăgătoare și prietenoasă poate fi realizată prin intermediul eforturilor coordonate ale guvernului, organizațiilor și a comunității. Prin promovarea destinațiilor turistice, susținerea inovației, profilarea culturală și protecția mediului înconjurător, putem construi o imagine pozitivă și durabilă pentru țara noastră în întreaga lume.</p>

        <div className="tag-share">
          <div className="line-tag">
            <span>Tag:</span>
            <Link to={`${process.env.PUBLIC_URL}/serviciu-dezvoltare-web`} onClick={scrollTop}>digitalizare,</Link>
            <Link to={`${process.env.PUBLIC_URL}/serviciu-identitate-vizuala`} onClick={scrollTop}>branding,</Link>
            <Link to={`${process.env.PUBLIC_URL}/serviciu-social-media`} onClick={scrollTop}>social media,</Link>
          </div>
          <div className="share-blog">
            <span>Share On:</span>
            <ul className="social-share-blog">
              <li>
                <a href="https://www.facebook.com/share?url=https://e-brandy.ro/blog-branding-romania" onClick={shareOnFacebook}>
                  <i className="fab fa-facebook-f" />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/intent/tweet?url=YOUR_URL_HERE" onClick={shareOnTwitter}>
                  <i className="fab fa-twitter" />
                </a>
              </li>
              <li>
                <a href="https://www.pinterest.com/pin/create/button/?url=YOUR_URL_HERE" onClick={shareOnPinterest}>
                  <i className="fab fa-pinterest-p" />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/sharer.php?u=YOUR_URL_HERE" onClick={shareOnInstagram}>
                  <i className="fab fa-instagram" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div >
    </>
  );
}

export default BlogDetailsContent;